import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [
    createPersistedState()
  ],
  state: {
    userInfo: null,
    token: ''
  },
  getters: {
    isLogedIn (state) {
      return state.token !== ''
    },
    token (state) {
      return state.token
    },
    userInfo (state) {
      return state.userInfo
    }
  },
  mutations: {
    login (state, { jwtToken, userInfo }) {
      console.log(jwtToken, userInfo)
      state.token = jwtToken
      state.userInfo = userInfo
    },
    logout (state) {
      state.token = ''
      state.userInfo = null
    }
  },
  actions: {
    login ({ commit }, user) {
      commit('login', user)
    },
    logout ({ commit }) {
      commit('logout')
    }
  },
  modules: {
  }
})
