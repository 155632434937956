<template>
  <div class="container-fluid main-content">
    <div class="row g-0">
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                年月選択
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <div class="input-group">
                      <label
                        class="input-group-text">
                        年月
                      </label>
                      <select
                        class="form-select"
                        id="selectedYm"
                        aria-describedby="selectedYm-feedback"
                        v-model="selectedYm">
                        <option value="" selected>未選択</option>
                        <option
                          v-for="f in ymList"
                          :key="f.key"
                          :value="f.key">
                          {{ f.yearMonthDisp }}
                        </option>

                      </select>
                    </div>
                  </div>
                  <div class="col-4">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="onLoadFrameDaysClick">
                      表示
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                予約日一覧
              </div>
              <div class="card-body days-main-content">
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text">
                        公開日
                      </span>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        id=""
                        aria-describedby="prayDate-feedback"
                        v-model="releaseDate">
                      <input
                        type="time"
                        class="form-control form-control-sm"
                        id=""
                        aria-describedby="prayDate-feedback"
                        v-model="releaseTime">

                    </div>
                  </div>
                </div>

                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            v-model="allselected"
                            @change="onAllChecksChange">
                        </div>
                      </th>
                      <th colspan="4">対象年月日</th>
                      <th>
                        <button
                          type="button"
                          class="btn btn-sm btn-outline-success"
                          :disabled="!enableBulkEditButton"
                          @click="onShowBulkEditDialogClick">
                          一括編集
                        </button>
                      </th>
                    </tr>
                    <tr>
                      <th colspan="3">開始時刻</th>
                      <th colspan="3">終了時刻</th>
                    </tr>
                    <tr>
                      <th colspan="2">組数</th>
                      <th colspan="2">組人数</th>
                      <th colspan="2">総人数</th>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    <template v-for="d in dayList" :key="d.prayDate">
                      <tr>
                        <td class="check-col">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              v-model="d.isSelected">
                          </div>
                        </td>
                        <td colspan="3" style="text-align: left;">
                          <template v-if="d.id === 0">
                            <p>{{ d.prayDateDisp }}</p>
                          </template>
                          <template v-else>
                            <button
                              type="button"
                              class="btn btn-link"
                              @click="onFrameEditClick(d.prayDateStr)">
                              {{ d.prayDateDisp }}
                            </button>
                          </template>
                        </td>
                        <td colspan="2">
                          <div class="input-group">
                            <label
                              class="input-group-text wide-text">
                              単位
                            </label>
                            <select
                              class="form-select"
                              aria-describedby="mainWishId-feedback"
                              v-model="d.unitMinute"
                              :disabled="d.id !== 0">
                              <option
                                v-for="w in unitMinuteList"
                                :key="w.key"
                                :value="w.key">
                                {{ w.value }}
                              </option>
                            </select>
                            <div id="mainWishId-feedback" class="invalid-feedback"></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <div class="input-group">
                            <span
                              class="input-group-text">
                              開始
                            </span>
                            <input
                              type="time"
                              class="form-control"
                              id=""
                              aria-describedby="prayDate-feedback"
                              v-model="d.inputStartTime"
                              :disabled="d.id !== 0">
                            <div id="prayDate-feedback" class="invalid-feedback"></div>
                          </div>
                        </td>
                        <td colspan="3">
                          <div class="input-group">
                            <span
                              class="input-group-text">
                              終了
                            </span>
                            <input
                              type="time"
                              class="form-control"
                              id=""
                              aria-describedby="prayDate-feedback"
                              v-model="d.inputClosingTime"
                              :disabled="d.id !== 0">
                            <div id="prayDate-feedback" class="invalid-feedback"></div>
                          </div>
                        </td>
                      </tr>
                      <tr class="bottom-row">
                        <td colspan="2">
                          <div class="input-group">
                            <span
                              class="input-group-text">
                              組数
                            </span>
                            <input
                              type="number"
                              class="form-control"
                              id=""
                              aria-describedby="prayDate-feedback"
                              v-model="d.groupCount"
                              :disabled="d.id !== 0">
                            <div id="prayDate-feedback" class="invalid-feedback"></div>
                          </div>
                        </td>
                        <td colspan="2">
                          <div class="input-group">
                            <span
                              class="input-group-text">
                              組人数
                            </span>
                            <input
                              type="number"
                              class="form-control"
                              id=""
                              aria-describedby="prayDate-feedback"
                              v-model="d.groupPeopleCount"
                              :disabled="d.id !== 0">
                            <div id="prayDate-feedback" class="invalid-feedback"></div>
                          </div>
                        </td>
                        <td colspan="2">
                          <div class="input-group">
                            <span
                              class="input-group-text">
                              総人数
                            </span>
                            <input
                              type="number"
                              class="form-control"
                              id=""
                              aria-describedby="prayDate-feedback"
                              v-model="d.totalPeopleCount"
                              :disabled="d.id !== 0">
                            <div id="prayDate-feedback" class="invalid-feedback"></div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>

              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col"></div>
                  <div class="col-auto">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary"
                      @click="onFrameDetailCreationClick"
                      :disabled="!canDaysCreation">
                      明細作成
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="modal fade"
                id="bulk-edit-modal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <span
                        class="modal-title">
                        複数日一括設定
                      </span>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="input-group">
                            <label
                              class="input-group-text wide-text">
                              予約枠単位
                            </label>
                            <select
                              class="form-select"
                              v-model="bulkEditDialogUnitMinute">

                              <option value="">未選択</option>
                              <option
                                v-for="w in unitMinuteList"
                                :key="w.key"
                                :value="w.key">
                                {{ w.value }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <span
                              class="input-group-text wide-text">
                              予約開始時刻
                            </span>
                            <input
                              type="time"
                              class="form-control"
                              v-model="bulkEditDialogStartTime">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <span
                              class="input-group-text wide-text">
                              予約終了時刻
                            </span>
                            <input
                              type="time"
                              class="form-control"
                              v-model="bulkEditDialogClosingTime">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <span
                              class="input-group-text wide-text">
                              １枠組数
                            </span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="bulkEditDialogGroups">
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="input-group">
                            <span
                              class="input-group-text wide-text">
                              組あたり人数
                            </span>
                            <input
                              type="number"
                              class="form-control"
                              v-model="bulkEditDialogPerplePerGroups">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-primary footer-button"
                        :disabled="!enableBulkEditApplyButton"
                        @click="onBulkEditApplyClick">
                        適用
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card" v-if="viewFrameEditPanel">
          <div class="card-header">
            予約枠明細
            <span v-if="frameForm.prayDateDisp !== ''">
              【{{ frameForm.prayDateDisp }}】
            </span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <div class="input-group">
                  <span
                    class="input-group-text">
                    開始
                  </span>
                  <input
                    type="time"
                    class="form-control"
                    id=""
                    aria-describedby="prayDate-feedback"
                    v-model="frameForm.inputStartTime">
                  <div id="prayDate-feedback" class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-4">
                <div class="input-group">
                  <span
                    class="input-group-text">
                    終了
                  </span>
                  <input
                    type="time"
                    class="form-control"
                    id=""
                    aria-describedby="prayDate-feedback"
                    v-model="frameForm.inputClosingTime">
                  <div id="prayDate-feedback" class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-4">
                <div class="input-group">
                  <label
                    class="input-group-text">
                    単位
                  </label>
                  <select
                    class="form-select"
                    aria-describedby="mainWishId-feedback"
                    v-model="frameForm.unitMinute">
                    <option
                      v-for="w in unitMinuteList"
                      :key="w.key"
                      :value="w.key">
                      {{ w.value }}
                    </option>
                  </select>
                  <div id="mainWishId-feedback" class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto ms-2 me-2">
                <button
                  type="button"
                  class="btn btn-sm btn-danger mt-1"
                  @click="onReCreateClick">
                  作り直し
                </button>
              </div>
              <div class="col-auto me-2">
                <button
                  type="button"
                  class="btn btn-sm btn-success mt-1"
                  @click="showFrameWishDaysDialog">
                  枠指定願意設定
                </button>
              </div>
            </div>
          </div>
          <hr/>
          <div class="card-body days-detail-content">
            <table class="table table-sm table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>時刻</th>
                  <th>組数</th>
                  <th>組人数</th>
                  <th>総人数</th>
                  <th>締め</th>
                  <th>理由</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="f in frameList" :key="f.startTime">
                  <td>
                    <button
                      type="button"
                      :class="`btn ${f.wishes.length !== 0 ? 'btn-success' : 'btn-outline-secondary'}`"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-custom-class="custom-tooltip"
                      data-bs-title="枠設定願意の設定"
                      @click="showFrameWishDialog(f.id)">
                      <i class="bi bi-award"></i>
                    </button>
                  </td>
                  <td>
                    {{ f.startTimeDisp }}
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control form-control-sm frame-detail-inputs"
                      v-model="f.groupCount">
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control form-control-sm frame-detail-inputs"
                      v-model="f.groupPeopleCount">
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control form-control-sm frame-detail-inputs"
                      v-model="f.totalPeopleCount">
                  </td>
                  <td>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        v-model="f.isClosed">
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="f.closeReason">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="modal fade"
            id="wish-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <span
                    class="modal-title">
                    枠指定願意設定
                  </span>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="row mb-1">
                    <div class="col-12">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="検索"
                        v-model="wishDialogSearchText">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto" v-for="w in filteredWishList" :key="w.id">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          :value="w.id"
                          :id="`wish-${w.id}`"
                          v-model="selectedFrameWishIds">
                        <label
                          class="form-check-label"
                          :for="`wish-${w.id}`">
                          {{ w.wishName }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    v-if="wishFrameDialogModeIsFrame"
                    type="button"
                    class="btn btn-primary footer-button"
                    @click="onWishesApplyClick">
                    適用
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-primary footer-button"
                    @click="onWishesToDayApplyClick">
                    適用
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col"></div>
              <div class="col-auto">
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="onUpdateFrameDetailsCilck">
                  明細更新
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import toast from '../lib/toast'
import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'

import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default {
  setup () {
    const store = useStore()

    const ymList = ref([])
    const dayList = ref([])
    const frameList = ref([])

    const wishList = ref([])

    const unitMinuteList = ref([])

    const releaseDate = ref('')
    const releaseTime = ref('')

    const selectedYm = ref('')
    const selectedDate = ref('')
    const allselected = ref(false)

    const selectedFrameWishIds = ref([])
    const selectedFrameDetailId = ref(0)
    const wishDialogSearchText = ref('')

    const bulkEditDialogUnitMinute = ref('')
    const bulkEditDialogStartTime = ref('')
    const bulkEditDialogClosingTime = ref('')
    const bulkEditDialogGroups = ref('')
    const bulkEditDialogPerplePerGroups = ref('')

    const wishFrameDialogModeIsFrame = ref(true)

    const frameForm = reactive({
      id: 0,
      prayDate: {},
      startTime: '',
      closingTime: '',
      unitMinute: 0,
      prayDateDisp: ''
    })

    onMounted(initialize)

    const canDaysCreation = computed(() => {
      if (dayList.value.length === 0) { return false }
      const ym = ymList.value.find(l => {
        return selectedYm.value === l.key
      })
      if (ym === undefined) { return false }
      return !ym.isExists
    })

    const viewFrameEditPanel = computed(() => {
      return selectedDate.value !== ''
    })

    const filteredWishList = computed(() => {
      return wishList.value.filter(w => {
        return w.isDateDesignation
      }).filter(w => {
        if (wishDialogSearchText.value === '') { return true }
        return w.wishName.indexOf(wishDialogSearchText.value) !== -1
      })
    })

    const enableBulkEditButton = computed(() => {
      return (dayList.value.filter(d => d.isSelected && d.id === 0).length > 0)
    })

    const enableBulkEditApplyButton = computed(() => {
      return bulkEditDialogUnitMinute.value !== '' ||
        bulkEditDialogStartTime.value !== '' ||
        bulkEditDialogClosingTime.value !== '' ||
        bulkEditDialogGroups.value !== '' ||
        bulkEditDialogPerplePerGroups.value !== ''
    })

    async function initialize () {
      loadingPanel.loadStart()
      const w = await httpClient.get(
        '/api/v1/Frame/YearMonthList',
        store.getters.token)

      ymList.value.splice(0)
      ymList.value.push(...w.data.payload)

      const u = await httpClient.get(
        '/api/v1/Frame/UnitMinuteList',
        store.getters.token)

      unitMinuteList.value.splice(0)
      unitMinuteList.value.push(...u.data.payload)

      loadingPanel.loadComplete()

      setTimeout(loadWishList, 2000)
    }

    async function loadWishList () {
      const u = await httpClient.get(
        '/api/v1/Wishes/GetAll',
        store.getters.token)

      wishList.value.splice(0)
      wishList.value.push(...u.data.payload)
    }

    async function onLoadFrameDaysClick () {
      if (selectedYm.value === '') { return }
      loadingPanel.loadStart()
      const w = await httpClient.get(
        `/api/v1/Frame/FrameDayList?ym=${selectedYm.value}`,
        store.getters.token)

      dayList.value.splice(0)
      dayList.value.push(...w.data.payload.days)
      releaseDate.value = w.data.payload.releaseDate
      releaseTime.value = w.data.payload.releaseTime

      selectedDate.value = ''
      loadingPanel.loadComplete()
    }

    async function onAllChecksChange () {
      dayList.value.forEach(d => {
        d.isSelected = allselected.value
      })
    }

    async function onFrameEditClick (dt) {
      selectedDate.value = dt
      loadingPanel.loadStart()
      const w = await httpClient.get(
        `/api/v1/Frame/GetByCode?code=${selectedDate.value}`,
        store.getters.token)

      frameList.value.splice(0)
      frameList.value.push(...w.data.payload)

      const f = await httpClient.get(
        `/api/v1/Frame/GetFrame?dt=${selectedDate.value}`,
        store.getters.token)

      frameForm.id = f.data.payload.id
      frameForm.prayDateDisp = f.data.payload.prayDateDisp
      frameForm.prayDate = f.data.payload.prayDate
      frameForm.inputStartTime = f.data.payload.inputStartTime
      frameForm.inputClosingTime = f.data.payload.inputClosingTime
      frameForm.unitMinute = f.data.payload.unitMinute

      loadingPanel.loadComplete()
    }

    async function onFrameDetailCreationClick () {
      loadingPanel.loadStart()
      const data = {
        days: dayList.value,
        releaseDate: releaseDate.value,
        releaseTime: releaseTime.value
      }

      const res = await httpClient.post(
        '/api/v1/Frame/Create',
        data,
        store.getters.token)

      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        // validator.setInvalidMessages(res.data.invalidList)
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }
      initialize()
      onLoadFrameDaysClick()
      toast.showSuccessToast({ message: '登録処理が完了しました' })
      loadingPanel.loadComplete()
    }

    async function onReCreateClick () {
      const r = window.confirm('この日の予約枠を再度作り直します。\n入力済みのクローズ情報等はすべて削除されますが、よろしいですか？')
      if (!r) { return }
      loadingPanel.loadStart()

      const data = {
        id: frameForm.id,
        prayDate: frameForm.prayDate,
        inputStartTime: frameForm.inputStartTime,
        inputClosingTime: frameForm.inputClosingTime,
        unitMinute: frameForm.unitMinute
      }

      const res = await httpClient.post(
        '/api/v1/Frame/ReCreate',
        data,
        store.getters.token)

      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        // validator.setInvalidMessages(res.data.invalidList)
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }
      initialize()
      onLoadFrameDaysClick()
      onFrameEditClick(selectedDate.value)
      toast.showSuccessToast({ message: '登録処理が完了しました' })
      loadingPanel.loadComplete()
    }

    async function onUpdateFrameDetailsCilck () {
      const r = window.confirm('予定明細の更新を行います。よろしいですか？')
      if (!r) { return }

      loadingPanel.loadStart()

      const data = frameList.value

      const res = await httpClient.put(
        `/api/v1/Frame/Update?id=${frameForm.id}`,
        data,
        store.getters.token)

      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        // validator.setInvalidMessages(res.data.invalidList)
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      const ymd = selectedDate.value
      initialize()
      onLoadFrameDaysClick()
      onFrameEditClick(ymd)
      toast.showSuccessToast({ message: '登録処理が完了しました' })
      loadingPanel.loadComplete()
    }

    async function showFrameWishDialog (id) {
      wishFrameDialogModeIsFrame.value = true

      const wish = frameList.value.find(f => {
        return f.id === id
      })
      if (wish === undefined) { return }

      selectedFrameDetailId.value = id
      selectedFrameWishIds.value.splice(0)
      selectedFrameWishIds.value.push(...wish.wishes.map(w => w.wishesId))

      const dialog = getWishDialog()
      dialog.show()
    }

    async function onWishesApplyClick () {
      const index = frameList.value.findIndex(f => {
        console.log('onWishesApplyClick', f.id, selectedFrameDetailId.value, (f.id === selectedFrameDetailId.value))
        return f.id === selectedFrameDetailId.value
      })

      const f = frameList.value[index]
      f.wishes.splice(0)
      f.wishes.push(...selectedFrameWishIds.value.map(w => {
        return {
          id: 0,
          frameDetails: selectedFrameDetailId.value,
          wishesId: w,
          wishName: ''
        }
      }))
      const dialog = getWishDialog()
      dialog.hide()
    }

    async function showFrameWishDaysDialog () {
      wishFrameDialogModeIsFrame.value = false
      selectedFrameWishIds.value.splice(0)

      const dialog = getWishDialog()
      dialog.show()
    }

    async function onWishesToDayApplyClick () {
      frameList.value.forEach(f => {
        f.wishes.splice(0)
        f.wishes.push(...selectedFrameWishIds.value.map(w => {
          return {
            id: 0,
            frameDetails: f.id,
            wishesId: w,
            wishName: ''
          }
        }))
      })

      const dialog = getWishDialog()
      dialog.hide()
    }

    async function onShowBulkEditDialogClick () {
      const selectedDays = dayList.value.filter(d => d.isSelected).length
      if (selectedDays === 0) {
        alert('一件以上選択してください')
        return
      }

      const dialog = getBulkEditDialog()
      dialog.show()
    }

    async function onBulkEditApplyClick () {
      dayList.value.filter(d => d.isSelected).forEach(d => {
        if (bulkEditDialogUnitMinute.value !== '') {
          d.unitMinute = bulkEditDialogUnitMinute.value
        }
        if (bulkEditDialogStartTime.value !== '') {
          d.inputStartTime = bulkEditDialogStartTime.value
        }
        if (bulkEditDialogClosingTime.value !== '') {
          d.inputClosingTime = bulkEditDialogClosingTime.value
        }
        if (bulkEditDialogGroups.value !== '') {
          d.groupCount = bulkEditDialogGroups.value
        }
        if (bulkEditDialogPerplePerGroups.value !== '') {
          d.groupPeopleCount = bulkEditDialogPerplePerGroups.value
        }

        if (!isNaN(d.groupCount) && !isNaN(d.groupPeopleCount)) {
          d.totalPeopleCount = '' + (parseInt(d.groupCount, 10) * parseInt(d.groupPeopleCount, 10))
        }
      })

      const dialog = getBulkEditDialog()
      dialog.hide()
    }

    function getWishDialog () {
      const dialog = bootstrap.Modal.getOrCreateInstance('#wish-modal')
      return dialog
    }

    function getBulkEditDialog () {
      const dialog = bootstrap.Modal.getOrCreateInstance('#bulk-edit-modal')
      return dialog
    }

    return {
      selectedYm,
      allselected,
      ymList,
      dayList,
      frameList,
      unitMinuteList,
      releaseDate,
      releaseTime,
      frameForm,
      wishDialogSearchText,
      selectedFrameWishIds,
      canDaysCreation,
      viewFrameEditPanel,
      filteredWishList,
      bulkEditDialogUnitMinute,
      bulkEditDialogStartTime,
      bulkEditDialogClosingTime,
      bulkEditDialogGroups,
      bulkEditDialogPerplePerGroups,
      enableBulkEditButton,
      enableBulkEditApplyButton,
      wishFrameDialogModeIsFrame,
      onLoadFrameDaysClick,
      onAllChecksChange,
      onFrameEditClick,
      onFrameDetailCreationClick,
      onReCreateClick,
      onUpdateFrameDetailsCilck,
      showFrameWishDialog,
      onWishesApplyClick,
      onShowBulkEditDialogClick,
      onBulkEditApplyClick,
      showFrameWishDaysDialog,
      onWishesToDayApplyClick
    }
  }
}
</script>
<style scoped>
.bottom-row {
  border-bottom: 2px solid black;
}

.check-col {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
}

.days-main-content {
  height: 75vh;
  overflow: scroll;
}
.days-detail-content {
  height: 72vh;
  overflow: scroll;
}

.frame-detail-inputs {
  width: 70px;
}
</style>
