<template>
  <div class="container-fluid main-content">
    <div class="row g-1">
      <div class="col-9">
        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                申込者情報
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-5">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        申込者名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="applicantName"
                        aria-describedby="applicantName-feedback"
                        v-model="form.reserve.applicantName">
                      <div id="applicantName-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                  <div class="col-7">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        メール
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="applicantEmailAddress"
                        aria-describedby="applicantEmailAddress-feedback"
                        v-model="form.reserve.applicantEmailAddress">
                      <div id="applicantEmailAddress-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                  <div class="col-5">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        電話番号
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="applicantTelNo"
                        aria-describedby="applicantTelNo-feedback"
                        v-model="form.reserve.applicantTelNo">
                      <div id="applicantTelNo-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                  <div class="col-7">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        ログインURL
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="loginUrl"
                        readonly
                        disabled
                        v-model="form.loginUrl">
                      <a
                        :href="form.loginUrl"
                        target="_blank"
                        class="btn btn-outline-success"
                        type="button">
                        <i class="bi bi-door-open"></i>
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                祈祷情報
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        祈祷日
                      </span>
                      <input
                        type="date"
                        class="form-control"
                        id="prayDate"
                        aria-describedby="prayDate-feedback"
                        v-model="form.reserve.prayDateInput"
                        @blur="onPrayDateBlur">
                      <div id="prayDate-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <label
                        class="input-group-text wide-text">
                        祈祷時刻
                      </label>
                      <select
                        class="form-select"
                        id="frameDetailsId"
                        aria-describedby="frameDetailsId-feedback"
                        v-model="form.reserve.frameDetailsId">
                        <option value="0" selected>未選択</option>
                        <option
                          v-for="f in frameList"
                          :key="f.id"
                          :value="f.id">
                          {{ f.selectItemDisp }}
                        </option>

                      </select>
                      <div id="frameDetailsId-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <label
                        class="input-group-text wide-text">
                        第一願意
                      </label>
                      <select
                        class="form-select"
                        id="mainWishId"
                        aria-describedby="mainWishId-feedback"
                        v-model="form.prayBasic.mainWishId"
                        @change="onWishIdSelectedChange(1)">
                        <option value="0" selected>未選択</option>
                        <option
                          v-for="w in mainWishList"
                          :key="w.id"
                          :value="w.id">
                          {{(w.prayCategoryId === 0 ? '【法】' : '【個】')}} {{ w.wishName }}
                        </option>

                      </select>
                      <div id="mainWishId-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        第一願意名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="mainWishName"
                        aria-describedby="mainWishName-feedback"
                        v-model="form.prayBasic.mainWishName"
                        :disabled="!mainWishNameEnable">
                      <div id="mainWishName-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <label
                        class="input-group-text wide-text">
                        第二願意
                      </label>
                      <select
                        class="form-select"
                        id="subWishId"
                        aria-describedby="subWishId-feedback"
                        v-model="form.prayBasic.subWishId"
                        @change="onWishIdSelectedChange(2)">
                        <option value="0" selected>未選択</option>
                        <option
                          v-for="w in subWishList"
                          :key="w.id"
                          :value="w.id">
                          {{(w.prayCategoryId === 0 ? '【法】' : '【個】')}} {{ w.wishName }}
                        </option>

                      </select>
                      <div id="subWishId-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        第二願意名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="subWishName"
                        aria-describedby="subWishName-feedback"
                        v-model="form.prayBasic.subWishName"
                        :disabled="!subWishNameEnable">
                      <div id="subWishName-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>

                <template
                  v-if="selectedCategory === 0">
                  <!-- ************************************** -->
                  <!--              法人                      -->
                  <!-- ************************************** -->
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願対象
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyContent"
                          aria-describedby="safetyContent-feedback"
                          v-model="form.categoryCorporate.safetyContent">
                        <div id="safetyContent-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願期間
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyPeriod"
                          aria-describedby="safetyPeriod-feedback"
                          v-model="form.categoryCorporate.safetyPeriod">
                        <div id="safetyPeriod-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <template
                  v-if="selectedCategory === 1">
                  <!-- ************************************** -->
                  <!--             個人・一般                  -->
                  <!-- ************************************** -->
                </template>

                <template
                  v-if="selectedCategory === 2">
                  <!-- ************************************** -->
                  <!--             成就・必勝                  -->
                  <!-- ************************************** -->
                  <div class="row mt-1">
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          第一志望
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="firstAspirations"
                          aria-describedby="firstAspirations-feedback"
                          v-model="form.categoryAccomplishment.firstAspirations">
                        <div id="firstAspirations-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="firstAspirationsKana"
                          aria-describedby="firstAspirationsKana-feedback"
                          v-model="form.categoryAccomplishment.firstAspirationsKana">
                        <div id="firstAspirationsKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          日付
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="firstAspirationsDateInput"
                          aria-describedby="firstAspirationsDateInput-feedback"
                          v-model="form.categoryAccomplishment.firstAspirationsDateInput">
                        <div id="firstAspirationsDateInput-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          第二志望
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="secondAspirations"
                          aria-describedby="secondAspirations-feedback"
                          v-model="form.categoryAccomplishment.secondAspirations">
                        <div id="secondAspirations-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="secondAspirationsKana"
                          aria-describedby="secondAspirationsKana-feedback"
                          v-model="form.categoryAccomplishment.secondAspirationsKana">
                        <div id="secondAspirationsKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          日付
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="secondAspirationsDateInput"
                          aria-describedby="secondAspirationsDateInput-feedback"
                          v-model="form.categoryAccomplishment.secondAspirationsDateInput">
                        <div id="secondAspirationsDateInput-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          第三志望
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="thirdAspirations"
                          aria-describedby="thirdAspirations-feedback"
                          v-model="form.categoryAccomplishment.thirdAspirations">
                        <div id="thirdAspirations-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="thirdAspirationsKana"
                          aria-describedby="thirdAspirationsKana-feedback"
                          v-model="form.categoryAccomplishment.thirdAspirationsKana">
                        <div id="thirdAspirationsKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          日付
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="thirdAspirationsDateInput"
                          aria-describedby="thirdAspirationsDateInput-feedback"
                          v-model="form.categoryAccomplishment.thirdAspirationsDateInput">
                        <div id="thirdAspirationsDateInput-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <template
                  v-if="selectedCategory === 3">
                  <!-- ************************************** -->
                  <!--             初宮・七五三                -->
                  <!-- ************************************** -->
                </template>

                <template
                  v-if="selectedCategory === 4">
                  <!-- ************************************** -->
                  <!--             夫婦祈願                    -->
                  <!-- ************************************** -->
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          出産予定日
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="expectedDateOfBirth"
                          aria-describedby="expectedDateOfBirth-feedback"
                          v-model="form.categoryCouple.expectedDateOfBirth">
                        <div id="expectedDateOfBirth-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <template
                  v-if="selectedCategory === 5">
                  <!-- ************************************** -->
                  <!--             安全祈願                    -->
                  <!-- ************************************** -->
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願対象
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyContent"
                          aria-describedby="safetyContent-feedback"
                          v-model="form.categorySafety.safetyContent">
                        <div id="safetyContent-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願期間
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyPeriod"
                          aria-describedby="safetyPeriod-feedback"
                          v-model="form.categorySafety.safetyPeriod">
                        <div id="safetyPeriod-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        墨書内容
                      </span>
                      <span
                        class="input-group-text">
                        <div
                          class="form-check form-switch form-check-inline"
                          v-for="wc in writingContents"
                          :key="wc.id">

                          <input
                            type="checkbox"
                            class="form-check-input"
                            :id="`writingContent_${wc.id}`"
                            :value="wc.id"
                            v-model="form.selectedWritingContents">
                          <label
                            class="form-check-label"
                            :for="`writingContent_${wc.id}`">
                            {{ wc.title }}
                          </label>

                        </div>

                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        祈祷料
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        id="prayFee"
                        aria-describedby="prayFee-feedback"
                        v-model="form.prayBasic.prayFee">
                      <div id="prayFee-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        参列人数
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        id="participantsNumber"
                        aria-describedby="participantsNumber-feedback"
                        v-model="form.reserve.participantsNumber">
                      <div id="participantsNumber-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                祈願者情報
              </div>
              <div
                class="card-body">

                <!-- ************************************** -->
                <!--              法人                      -->
                <!-- ************************************** -->
                <template
                  v-if="selectedCategory === 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          会社・団体名
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="companyName"
                          aria-describedby="companyName-feedback"
                          v-model="form.prayerCorprate.companyName">
                        <input
                          type="text"
                          class="form-control"
                          id="companyNameKana"
                          aria-describedby="companyNameKana-feedback"
                          v-model="form.prayerCorprate.companyNameKana">
                        <div id="companyName-feedback" class="invalid-feedback"></div>
                        <div id="companyNameKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          支店・部署名
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="branchAndDepartmentName"
                          aria-describedby="branchAndDepartmentName-feedback"
                          v-model="form.prayerCorprate.branchAndDepartmentName">
                        <input
                          type="text"
                          class="form-control"
                          id="branchAndDepartmentNameKana"
                          aria-describedby="branchAndDepartmentNameKana-feedback"
                          v-model="form.prayerCorprate.branchAndDepartmentNameKana">
                        <div id="branchAndDepartmentName-feedback" class="invalid-feedback"></div>
                        <div id="branchAndDepartmentNameKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          代表者役職
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="representativeJobTitle"
                          aria-describedby="representativeJobTitle-feedback"
                          v-model="form.prayerCorprate.representativeJobTitle">
                        <input
                          type="text"
                          class="form-control"
                          id="representativeJobTitleKana"
                          aria-describedby="representativeJobTitleKana-feedback"
                          v-model="form.prayerCorprate.representativeJobTitleKana">
                        <div id="representativeJobTitle-feedback" class="invalid-feedback"></div>
                        <div id="representativeJobTitleKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          代表者名
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="representativeName"
                          aria-describedby="representativeName-feedback"
                          v-model="form.prayerCorprate.representativeName">
                        <input
                          type="text"
                          class="form-control"
                          id="representativeNameKana"
                          aria-describedby="representativeNameKana-feedback"
                          v-model="form.prayerCorprate.representativeNameKana">
                        <div id="representativeName-feedback" class="invalid-feedback"></div>
                        <div id="representativeNameKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                </template>

                <template
                  v-if="selectedCategory !== 0">
                  <!-- ************************************** -->
                  <!--             法人以外                    -->
                  <!-- ************************************** -->
                  <div class="row g-1">
                    <div
                      class="col"
                      v-for="(p, i) in form.prayerPersonal"
                      :key="p.id">

                      <div class="card">
                        <div class="card-header">
                          <template v-if="p.attributes === 0">
                            御本人
                          </template>
                          <template v-if="p.attributes === 1">
                            夫
                          </template>
                          <template v-if="p.attributes === 2">
                            妻
                          </template>
                          <template v-if="p.attributes === 3">
                            お祝い子 {{ i + 1 }}人目
                          </template>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text middle-text">
                                  氏名
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`prayerName_${i}`"
                                  :aria-describedby="`prayerName_${i}-feedback`"
                                  v-model="p.prayerName">
                                <div :id="`prayerName_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text middle-text">
                                  よみ
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`prayerNameKana_${i}`"
                                  :aria-describedby="`prayerNameKana_${i}-feedback`"
                                  v-model="p.prayerNameKana">
                                <div :id="`prayerNameKana_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="input-group">
                                <span
                                  class="input-group-text middle-text">
                                  性別
                                </span>
                                <select
                                  class="form-select"
                                  :id="`gendar_${i}`"
                                  :aria-describedby="`gendar_${i}-feedback`"
                                  v-model="p.gendar">
                                  <option value="0">未指定</option>
                                  <option value="1">男性</option>
                                  <option value="2">女性</option>
                                </select>
                                <div :id="`gendar_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>

                            <div class="col-4">
                              <div class="input-group">
                                <span
                                  class="input-group-text middle-text">
                                  誕生日
                                </span>
                                <input
                                  type="date"
                                  class="form-control"
                                  :id="`birthdayInput_${i}`"
                                  :aria-describedby="`birthdayInput_${i}-feedback`"
                                  v-model="p.birthdayInput">
                                <div :id="`birthdayInput_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="input-group">
                                <span
                                  class="input-group-text middle-text">
                                  祈祷料
                                </span>
                                <input
                                  type="number"
                                  class="form-control"
                                  :id="`prayFee_${i}`"
                                  :aria-describedby="`prayFee_${i}-feedback`"
                                  v-model="p.prayFee">
                                <div :id="`prayFee_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </template>

                <template
                  v-if="selectedCategory === 3">
                  <!-- ************************************** -->
                  <!--             初宮・七五三                -->
                  <!-- ************************************** -->
                  <div class="row mt-1 mb-1">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-header">
                          ご両親情報
                        </div>
                        <div class="card-body">

                          <div class="row mt-1">
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text wide-text">
                                  父氏名
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="fatherName"
                                  aria-describedby="fatherName-feedback"
                                  v-model="form.categoryChild.fatherName">
                                <div id="fatherName-feedback" class="invalid-feedback"></div>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="fatherNameKana"
                                  aria-describedby="fatherNameKana-feedback"
                                  v-model="form.categoryChild.fatherNameKana">
                                <div id="fatherNameKana-feedback" class="invalid-feedback"></div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-1">
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text wide-text">
                                  母氏名
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="motherName"
                                  aria-describedby="motherName-feedback"
                                  v-model="form.categoryChild.motherName">
                                <div id="motherName-feedback" class="invalid-feedback"></div>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="motherNameKana"
                                  aria-describedby="motherNameKana-feedback"
                                  v-model="form.categoryChild.motherNameKana">
                                <div id="motherNameKana-feedback" class="invalid-feedback"></div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="row mt-1">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        郵便番号
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="postCode"
                        aria-describedby="postCode-feedback"
                        v-model="form.prayBasic.postCode">
                        <button
                          class="btn btn-outline-success"
                          type="button"
                          @click="onPostCodeSearchClick">検索</button>
                      <div id="postCode-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col"></div>
                  <div class="col-auto">
                    <a
                      :href="`https://www.google.co.jp/maps?q=${form.prayBasic.address1}${form.prayBasic.address2}${form.prayBasic.address3}`"
                      target="_blank"
                      class="btn btn-outline-success">
                      <i class="bi bi-geo-fill"></i>
                    </a>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        住所１
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address1"
                        aria-describedby="address1-feedback"
                        v-model="form.prayBasic.address1">
                      <div id="address1-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address1Kana"
                        aria-describedby="address1Kana-feedback"
                        v-model="form.prayBasic.address1Kana">
                      <div id="address1Kana-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        住所２
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address2"
                        aria-describedby="address2-feedback"
                        v-model="form.prayBasic.address2">
                      <div id="address2-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address2Kana"
                        aria-describedby="address2Kana-feedback"
                        v-model="form.prayBasic.address2Kana">
                      <div id="address2Kana-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        住所３
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address3"
                        aria-describedby="address3-feedback"
                        v-model="form.prayBasic.address3">
                      <div id="address3-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address3Kana"
                        aria-describedby="address3Kana-feedback"
                        v-model="form.prayBasic.address3Kana">
                      <div id="address3Kana-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-5">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        領収書宛名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="receiptAddressee"
                        aria-describedby="receiptAddressee-feedback"
                        v-model="form.prayBasic.receiptAddressee">
                      <div id="receiptAddressee-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        領収書記載金額
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        id="receiptSummaryAmount"
                        aria-describedby="receiptSummaryAmount-feedback"
                        v-model="form.prayBasic.receiptSummaryAmount">
                      <div id="receiptSummaryAmount-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-check form-switch form-check-inline mt-2">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="disableReceipt"
                        v-model="form.prayBasic.disableReceipt">
                      <label
                        class="form-check-label"
                        for="disableReceipt">
                        領収書不要
                      </label>

                    </div>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        備考
                      </span>
                      <textarea
                        class="form-control"
                        id="remarks"
                        rows="4"
                        aria-describedby="remarks-feedback"
                        v-model="form.prayBasic.remarks"
                        disabled
                        readonly>
                      </textarea>
                      <textarea
                        class="form-control ms-1"
                        id="slipRemarks"
                        rows="4"
                        placeholder="読み札用備考"
                        aria-describedby="slipRemarks-feedback"
                        v-model="form.prayBasic.slipRemarks">
                      </textarea>
                      <button
                        class="btn btn-outline-danger"
                        type="button"
                        @click="onSlipRemarkClear">
                        ク<br>リ<br>ア
                      </button>
                      <div id="slipRemarks-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                ステータス
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-auto">
                    <template v-if="isCopyMode">
                      <h2>
                        <span class="badge bg-primary">コピー</span>
                      </h2>
                    </template>
                    <template v-else>
                      <h2 v-if="reserveId === 0">
                        <span class="badge bg-info">新規</span>
                      </h2>
                      <h2 v-if="reserveId !== 0 && !form.reserve.isDeleted">
                        <span class="badge bg-success">予約済み</span>
                      </h2>
                      <h2 v-if="form.reserve.isDeleted">
                        <span class="badge bg-warning">キャンセル</span>
                      </h2>
                    </template>
                  </div>
                  <div class="col-auto">
                    <h2 v-if="form.booking.reserveId !== 0">
                      <span class="badge text-bg-light">
                        <a
                          :href="`/reserve/edit/${form.booking.reserveId}`"
                          target="_blank">
                          追加予約
                        </a>
                      </span>
                    </h2>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                最終更新情報
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-auto ms-2">
                    新規作成：{{ form.reserve.createdAtDisp }}<br>
                    最終更新：{{ form.reserve.updatedAtDisp }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                神札
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        特小
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="sizeXs"
                        aria-describedby="sizeXs-feedback"
                        v-model="form.fudaSize.sizeXs">
                      <div id="sizeXs-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        小
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="sizeSm"
                        aria-describedby="sizeSm-feedback"
                        v-model="form.fudaSize.sizeSm">
                      <div id="sizeSm-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        中
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="sizeMd"
                        aria-describedby="sizeMd-feedback"
                        v-model="form.fudaSize.sizeMd">
                      <div id="sizeMd-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        大
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="sizeLg"
                        aria-describedby="sizeLg-feedback"
                        v-model="form.fudaSize.sizeLg">
                      <div id="sizeLg-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        特大
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="sizeXl"
                        aria-describedby="sizeXl-feedback"
                        v-model="form.fudaSize.sizeXl">
                      <div id="sizeXl-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        交通
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="sizeCar"
                        aria-describedby="sizeCar-feedback"
                        v-model="form.fudaSize.sizeCar">
                      <div id="sizeCar-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                撤下品
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        １万
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="souvenir01"
                        aria-describedby="souvenir01-feedback"
                        v-model="form.souvenir.souvenir01">
                      <div id="souvenir01-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        ２万
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="souvenir02"
                        aria-describedby="sizeLg-feedback"
                        v-model="form.souvenir.souvenir02">
                      <div id="souvenir02-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        ３万
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="souvenir03"
                        aria-describedby="souvenir03-feedback"
                        v-model="form.souvenir.souvenir03">
                      <div id="souvenir03-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        ５万
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="souvenir04"
                        aria-describedby="souvenir04-feedback"
                        v-model="form.souvenir.souvenir04">
                      <div id="souvenir04-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text small-text">
                        10万
                      </span>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        id="souvenir05"
                        aria-describedby="sizeXs-feedback"
                        v-model="form.souvenir.souvenir05">
                      <div id="souvenir05-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                メモ
              </div>
              <div class="card-body">
                <textarea
                  class="form-control"
                  id="memo"
                  rows="8"
                  v-model="form.reserve.memo">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                変更履歴
              </div>
              <div class="card-body history-list">
                <img
                  src="../assets/loading-l-3.gif"
                  v-if="historyLoading"/>

                <ul
                  class="list-group"
                  v-else>

                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                    v-for="h in historyList"
                    :key="h.id">
                    <a
                      :href="`/reserve/history/${h.id}`"
                      target="_blank"
                      class="btn btn-sm btn-outline-primary">
                      <i class="bi bi-box-arrow-up-right"></i>
                    </a>
                    {{ h.historyAddedAtDisp }}
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                メール送信履歴
              </div>
              <div class="card-body history-list">
                <img
                  src="../assets/loading-l-3.gif"
                  v-if="mailLoading"/>

                <ul
                  class="list-group"
                  v-else>

                  <li
                    class="list-group-item list-group-item-action"
                    style="text-align: left;"
                    v-for="m in mailList"
                    :key="m.id">

                    <div class="d-flex w-100 justify-content-between">
                      <template v-if="m.isSent">
                        <span class="badge text-bg-success">送信済</span>
                      </template>
                      <template v-if="m.isDraft && !m.isSent">
                        <span class="badge text-bg-warning">未送信</span>
                        <span class="badge text-bg-info">保存済</span>
                      </template>
                      <template v-if="!m.isDraft && !m.isSent">
                        <span class="badge text-bg-danger">エラー</span>
                      </template>

                      <small>
                        <template v-if="m.isSent">
                          {{ m.sentAtDisp }}
                        </template>
                        <template v-if="m.isDraft">
                          {{ m.lastSavedAtDisp }}
                        </template>
                        <template v-if="!m.isDraft">
                        </template>
                      </small>

                    </div>
                    <p class="mb-0">
                      <button
                        type="button"
                        class="btn btn-link"
                        @click="onHistoryMailClick(m.id)">
                        {{ m.subject }}
                      </button>
                    </p>

                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="mailer-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <span
              class="modal-title">
              <div class="input-group">
                <select
                  class="form-select"
                  v-model="mailTemplateClass"
                  aria-label="Example select with button addon">
                  <option selected value="">未選択</option>
                  <option value="0">予約通知</option>
                  <option value="1">予約確定</option>
                  <option value="2">リマインド</option>
                  <option value="3">キャンセル</option>
                  <option value="9">その他</option>
                </select>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  :disabled="selectedMailTemplates"
                  @click="onMailBodyCreateClick">
                  本文生成
                </button>
              </div>
            </span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row" v-if="mailHistoryId !== 0">
              <div class="col-auto">
                <template v-if="mailHistoryData.isSent">
                  <span class="badge text-bg-success">送信済</span>
                </template>
                <template v-if="mailHistoryData.isDraft && !mailHistoryData.isSent">
                  <span class="badge text-bg-warning">未送信</span>
                  <span class="badge text-bg-info">保存済</span>
                </template>
                <template v-if="!mailHistoryData.isDraft && !mailHistoryData.isSent">
                  <span class="badge text-bg-danger">エラー</span>
                </template>
              </div>
              <div class="col"></div>
              <div class="col-auto">
                <small>
                  {{ mailHistoryData.sentAt }}
                </small>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-12">
                <input
                  type="text"
                  class="form-control"
                  placeholder="件名"
                  v-model="mailSubject">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <textarea
                  class="form-control"
                  rows="12"
                  placeholder="本文"
                  v-model="mailBody"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary footer-button"
              @click="onMailDraftSaveClick"
              :disabled="mailDialogButtonEnable">
              下書き保存
            </button>
            <button
              type="button"
              class="btn btn-primary footer-button"
              :disabled="mailDialogButtonEnable"
              @click="onMailSendClick">
              送信
            </button>
          </div>
        </div>
      </div>
    </div>
    <nav
      class="navbar fixed-bottom bg-body-tertiary bg-primary"
      data-bs-theme="dark">
      <div class="container-fluid">
        <div class="col col-lg-2 col-md-3"></div>
        <div class="col" v-if="reserveId === 0">
          <div class="btn-group dropup">
            <a
              class="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              ダミーデータ
            </a>

            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click="setCorpDummy()">法人</a></li>
              <li><a class="dropdown-item" href="#" @click="setPersonalDummy()">個人・一般</a></li>
            </ul>
          </div>
        </div>
        <div class="col">
          <button
            v-if="reserveId !== 0 && !form.reserve.isDeleted"
            class="btn btn-danger footer-button"
            @click="onCancelButtonClick">
            キャンセル
          </button>
          <button
            v-if="reserveId !== 0 && form.reserve.isDeleted"
            class="btn btn-warning footer-button"
            @click="onCancelRestoreButtonClick">
            取消復元
          </button>
        </div>

        <div class="col">
          <button
            class="btn btn-info footer-button"
            :disabled="reserveId === 0"
            @click="onCopyButtonClick">
            コピー
          </button>

        </div>

        <div class="col">
          <button
            class="btn btn-warning footer-button"
            :disabled="reserveId === 0"
            @click="onMailDialogOpenClick">
            メール送信
          </button>

        </div>
        <div class="col">
          <button
            class="btn btn-success footer-button"
            :disabled="reserveId === 0"
            @click="onDownloadReadingSlipClick">
            読み札出力
          </button>
          <a
            id="download_costume_slip_link"
            href="#"
            style="display:none;"
            download="読み札.xlsx">
            ダウンロード
          </a>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-primary footer-button"
            @click="onRegisterButtonClick">
            更新
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { onMounted, ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import toast from '../lib/toast'
import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'
import validator from '../lib/validator'
import postCodeApi from '../lib/postCode'

import excelDownloader from '../lib/excelDownloader'

export default {
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const form = reactive({
      reserve: {},
      prayBasic: {},
      prayerCorprate: {},
      prayerPersonal: {},
      categoryAccomplishment: {},
      categoryChild: {},
      categoryCorporate: {},
      categoryCouple: {},
      categoryPersonal: {},
      categorySafety: {},
      selectedWritingContents: [],
      fudaSize: {},
      souvenir: {},
      loginUrl: '',
      booking: {}
    })
    const wishes = reactive([])
    const frameList = reactive([])
    const historyList = reactive([])
    const historyLoading = ref(true)

    const mailList = reactive([])
    const mailLoading = ref(true)

    const writingContents = reactive([])

    const reserveId = ref(0)

    const mainWishNameEnable = ref(false)
    const subWishNameEnable = ref(false)

    const selectedCategory = ref(-1)

    const mailTemplateClass = ref('')
    const mailSubject = ref('')
    const mailBody = ref('')
    const mailHistoryId = ref(0)
    const mailHistoryData = reactive({
      isSent: false,
      isDraft: false,
      sentAt: ''
    })

    const isCopyMode = ref(false)

    let beforePrayDate = ''

    const mainWishList = computed(() => {
      return wishes
    })

    const subWishList = computed(() => {
      return wishes.filter((w) => {
        return w.prayCategoryId === selectedCategory.value
      })
    })

    const selectedMailTemplates = computed(() => {
      return mailTemplateClass.value === ''
    })

    const mailDialogButtonEnable = computed(() => {
      return mailSubject.value.trim() === '' || mailBody.value.trim() === ''
    })

    onMounted(initialize)

    async function initialize (rid) {
      loadingPanel.loadStart()
      if (rid === undefined) {
        const { id } = route.params
        reserveId.value = parseInt(id, 10)
      }

      const w = await httpClient.get(
        '/api/v1/Wishes/GetAll',
        store.getters.token)

      wishes.splice(0)
      wishes.push(...w.data.payload)

      console.log('onMounted', reserveId.value)

      const res = await httpClient.get(
        `/api/v1/Reserve/Find?id=${reserveId.value}`,
        store.getters.token)

      const p = res.data.payload
      form.reserve = p.reserve
      form.prayBasic = p.prayBasic
      form.prayerCorprate = p.prayerCorprate
      form.prayerPersonal = p.prayerPersonal
      form.categoryAccomplishment = p.categoryAccomplishment
      form.categoryChild = p.categoryChild
      form.categoryCorporate = p.categoryCorporate
      form.categoryCouple = p.categoryCouple
      form.categoryPersonal = p.categoryPersonal
      form.categorySafety = p.categorySafety
      form.selectedWritingContents = p.selectedWritingContents
      form.fudaSize = p.fudaSize
      form.souvenir = p.souvenir
      form.loginUrl = p.serverURL
      form.booking = p.overbooking

      const fid = route.query.fid
      const dt = route.query.dt

      if (fid && dt) {
        // 日付・時刻指定の場合
        form.reserve.prayDateInput = dt
        form.reserve.frameDetailsId = window.parseInt(fid, 10)
      }

      if (form.reserve.prayDateInput !== '') {
        loadFrameDetails(form.reserve.prayDateInput)
      }

      const wish = getWishByList(form.prayBasic.mainWishId)
      if (wish) {
        selectedCategory.value = wish.prayCategoryId
        console.log(selectedCategory.value)
        loadWritingContents()
      }

      loadingPanel.loadComplete()

      onWishIdSelectedChange(1)
      onWishIdSelectedChange(2)

      setTimeout(loadHistories, 2000)
      setTimeout(loadMailHistories, 3000)
    }

    async function loadHistories (id) {
      const rid = id || 0
      // if (reserveId.value === 0 && rid === 0) { return }
      if (rid !== 0) {
        reserveId.value = rid
      }
      const res = await httpClient.get(
        `/api/v1/Reserve/Histries?id=${reserveId.value}`,
        store.getters.token)

      historyList.splice(0)
      historyList.push(...res.data.payload)
      historyLoading.value = false

      const res2 = await httpClient.get(
        `/api/v1/Mail/Histories?id=${reserveId.value}`,
        store.getters.token)

      mailList.splice(0)
      mailList.push(...res2.data.payload)
      mailLoading.value = false
    }

    async function loadMailHistories () {
      // if (reserveId.value === 0) { return }

      mailLoading.value = true
      const res = await httpClient.get(
        `/api/v1/Mail/Histories?id=${reserveId.value}`,
        store.getters.token)

      mailList.splice(0)
      mailList.push(...res.data.payload)
      mailLoading.value = false
    }

    async function loadFrameDetails (dt) {
      if (beforePrayDate === dt) { return }
      const res = await httpClient.get(
        `/api/v1/Frame/GetByCode?code=${dt}`,
        store.getters.token)

      frameList.splice(0)
      frameList.push(...res.data.payload)
      beforePrayDate = dt
    }

    async function onPrayDateBlur () {
      loadFrameDetails(form.reserve.prayDateInput)
    }

    async function onWishIdSelectedChange (wishNumber) {
      const wid = wishNumber === 1 ? form.prayBasic.mainWishId : form.prayBasic.subWishId
      const wish = getWishByList(wid)

      if (wid === '0') {
        if (wishNumber === 1) {
          mainWishNameEnable.value = false
          form.prayBasic.mainWishName = ''
        }
        if (wishNumber === 2) {
          subWishNameEnable.value = false
          form.prayBasic.subWishName = ''
        }
      }

      if (!wish) { return }
      if (wishNumber === 1) {
        mainWishNameEnable.value = wish.isManualInput
        if (!wish.isManualInput) {
          form.prayBasic.mainWishName = wish.wishName
        }
        selectedCategory.value = wish.prayCategoryId
        loadWritingContents()
      }
      if (wishNumber === 2) {
        subWishNameEnable.value = wish.isManualInput
        if (!wish.isManualInput) {
          form.prayBasic.subWishName = wish.wishName
        }
      }
    }

    async function loadWritingContents () {
      const res = await httpClient.get(
        `/api/v1/WritingContent/GetByCode?code=${form.prayBasic.mainWishId}`,
        store.getters.token)

      writingContents.splice(0)
      writingContents.push(...res.data.payload)

      form.selectedWritingContents = form.selectedWritingContents.filter(id => {
        const exists = res.data.payload.find(wc => {
          return id === wc.id
        })
        return (exists !== undefined)
      })
    }

    function onPostCodeSearchClick () {
      loadingPanel.loadStart()
      postCodeApi.getAddressByPostCode(form.prayBasic.postCode).then(res => {
        console.log(res)
        if (res.length === 1) {
          const data = res[0]
          form.prayBasic.address1 = `${data.address1} ${data.address2} ${data.address3}`
          form.prayBasic.address1Kana = `${data.kana1} ${data.kana2} ${data.kana3}`
        }
        loadingPanel.loadComplete()
      }).catch(e => {
        alert(`${form.prayBasic.postCode}は有効な郵便番号として判定できませんでした。`)
        const c = document.getElementById('postCode')
        if (c !== undefined) { c.focus() }
        loadingPanel.loadComplete()
      })
    }
    /*
    function onRemarksCopyClick () {
      const remark = document.getElementById('remarks')
      const { selectionStart, selectionEnd } = remark
      if (selectionStart === selectionEnd) {
        form.prayBasic.slipRemarks += '\n' + form.prayBasic.remarks
      } else {
        const text = form.prayBasic.remarks.substring(selectionStart, selectionEnd)
        form.prayBasic.slipRemarks += '\n' + text
      }
    }
    */
    async function onRegisterButtonClick () {
      loadingPanel.loadStart()
      validator.clearInvalidMessage()

      const data = {
        reserve: form.reserve,
        prayBasic: form.prayBasic,
        prayerCorprate: form.prayerCorprate,
        prayerPersonal: form.prayerPersonal,
        categoryAccomplishment: form.categoryAccomplishment,
        categoryChild: form.categoryChild,
        categoryCorporate: form.categoryCorporate,
        categoryCouple: form.categoryCouple,
        categoryPersonal: form.categoryPersonal,
        categorySafety: form.categorySafety,
        selectedWritingContents: form.selectedWritingContents,
        fudaSize: form.fudaSize,
        souvenir: form.souvenir
      }

      console.log('reserveId.value', reserveId.value, (reserveId.value === 0), (reserveId.value === '0'))

      const promise = (reserveId.value === 0 || isCopyMode.value)
        ? httpClient.post(
          '/api/v1/Reserve/Create',
          data,
          store.getters.token)
        : httpClient.put(
          `/api/v1/Reserve/Update?id=${reserveId.value}`,
          data,
          store.getters.token)

      const res = await promise
      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        validator.setInvalidMessages(res.data.invalidList)
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      if (reserveId.value === 0 || isCopyMode.value) {
        isCopyMode.value = false
        reserveId.value = res.data.payload.reserve.id
        router.push(`/reserve/edit/${res.data.payload.reserve.id}`)
      }

      initialize(reserveId.value)
      toast.showSuccessToast({ message: '登録処理が完了しました' })

      loadingPanel.loadComplete()
    }

    async function onMailDialogOpenClick () {
      const mailer = getMailDialog()
      mailHistoryId.value = 0
      mailTemplateClass.value = ''
      mailSubject.value = ''
      mailBody.value = ''
      mailer.show()
    }

    async function onMailBodyCreateClick () {
      const res = await httpClient.get(
        `/api/v1/Mail/LoadTemplate?rid=${reserveId.value}&pclass=${mailTemplateClass.value}`,
        store.getters.token)

      mailSubject.value = ''
      mailBody.value = ''

      if (res.data.isValid) {
        mailSubject.value = res.data.payload.subject
        mailBody.value = res.data.payload.body
      }
    }

    async function onMailDraftSaveClick () {
      const r = confirm('メール内容を保存します。\nよろしいですか？')
      if (!r) { return false }

      loadingPanel.loadStart()

      const res = await httpClient.post(
        '/api/v1/Mail/Create',
        {
          reserveId: reserveId.value,
          subject: mailSubject.value,
          body: mailBody.value,
          beforeMailId: mailHistoryId.value
        },
        store.getters.token)

      loadingPanel.loadComplete()
      if (res.data.isValid) {
        const mailer = getMailDialog()
        mailer.hide()
        toast.showSuccessToast({ message: 'メールの保存が完了しました' })
        setTimeout(loadMailHistories, 3000)
      }
    }

    async function onMailSendClick () {
      const r1 = confirm('メールを送信します。\nよろしいですか？')
      if (!r1) { return false }

      const r2 = confirm('本当によろしいですか？')
      if (!r2) { return false }

      loadingPanel.loadStart()

      const res = await httpClient.post(
        '/api/v1/Mail/SendMail',
        {
          reserveId: reserveId.value,
          subject: mailSubject.value,
          body: mailBody.value,
          beforeMailId: mailHistoryId.value
        },
        store.getters.token)

      loadingPanel.loadComplete()
      if (res.data.isValid) {
        const mailer = getMailDialog()
        mailer.hide()
        toast.showSuccessToast({ message: 'メールの送信指示が完了しました' })
        setTimeout(loadMailHistories, 8000)
      }
    }

    async function onHistoryMailClick (id) {
      mailHistoryId.value = id
      const res = await httpClient.get(
        `/api/v1/Mail/Find?id=${id}`,
        store.getters.token)

      mailSubject.value = ''
      mailBody.value = ''

      if (res.data.isValid) {
        const p = res.data.payload
        mailSubject.value = p.subject
        mailBody.value = p.body
        mailHistoryData.isDraft = p.isDraft
        mailHistoryData.isSent = p.isSent
        mailHistoryData.sentAt = p.isSent ? p.sentAtDisp : p.lastSavedAtDisp
      }
      const mailer = getMailDialog()
      mailer.show()
    }

    async function onDownloadReadingSlipClick () {
      const r = confirm('読み札のダウンロードを行います。\nよろしいですか？')
      if (!r) { return }

      loadingPanel.loadStart()

      const res = await httpClient.requestFileByPost(
        '/api/v1/Report/ReadingSlip',
        { ids: [reserveId.value] },
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      excelDownloader.loadExcelFromBinary(res.data, 'download_costume_slip_link')

      toast.showSuccessToast({ message: '出力が完了しました。' })
      loadingPanel.loadComplete()
    }

    async function onCancelButtonClick () {
      const r = confirm('この予約をキャンセルします。\nよろしいですか？')
      if (!r) { return }

      loadingPanel.loadStart()

      const res = await httpClient.delete(
        `/api/v1/Reserve/Delete?id=${reserveId.value}`,
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      toast.showSuccessToast({ message: 'キャンセル処理が完了しました。' })
      initialize()
      loadingPanel.loadComplete()
    }

    async function onCancelRestoreButtonClick () {
      const r = confirm('この予約をキャンセルから復元します。\nよろしいですか？')
      if (!r) { return }

      loadingPanel.loadStart()

      const res = await httpClient.post(
        `/api/v1/Reserve/Restore?id=${reserveId.value}`,
        {},
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      toast.showSuccessToast({ message: 'キャンセルの復元が完了しました。' })
      initialize()
      loadingPanel.loadComplete()
    }

    function getWishByList (wid) {
      const wish = wishes.find((w) => {
        return w.id === wid
      })
      return wish
    }

    function getMailDialog () {
      const mailer = bootstrap.Modal.getOrCreateInstance('#mailer-modal')
      return mailer
    }

    function onCopyButtonClick () {
      const res = window.confirm('この予定をコピーして新規の予約を作成します。よろしいですか？')
      if (!res) { return }

      loadingPanel.loadStart()

      isCopyMode.value = true
      reserveId.value = 0
      form.reserve.id = 0
      form.reserve.prayDateInput = ''
      form.reserve.frameDetailsId = 0

      form.prayerCorprate.id = 0
      form.prayerPersonal.forEach(p => {
        p.id = 0
      })
      loadHistories()
      loadMailHistories()

      loadingPanel.loadComplete()
    }

    function onSlipRemarkClear () {
      form.prayBasic.slipRemarks = ''
    }

    async function setCorpDummy () {
      selectedCategory.value = 0
      const wi = wishes.find((w) => w.prayCategoryId === selectedCategory.value)
      form.prayBasic.mainWishId = wi.id
      onWishIdSelectedChange(1)

      form.reserve.applicantName = '日本橋日枝神社担当者'
      form.reserve.applicantEmailAddress = 'shj@hiejinja.net'

      form.prayerCorprate.companyName = 'ダミー予約'
      form.prayerCorprate.companyNameKana = 'ダミー予約'
    }

    async function setPersonalDummy () {
      selectedCategory.value = 1
      const wi = wishes.find((w) => w.prayCategoryId === selectedCategory.value)
      form.prayBasic.mainWishId = wi.id
      onWishIdSelectedChange(1)

      form.reserve.applicantName = '日本橋日枝神社担当者'
      form.reserve.applicantEmailAddress = 'shj@hiejinja.net'

      form.prayerPersonal[0].attributes = 0
      form.prayerPersonal[0].prayerName = 'ダミー予約'
      form.prayerPersonal[0].prayerNameKana = 'ダミー予約'
    }

    return {
      /* variables */
      form,
      wishes,
      frameList,
      historyList,
      mailList,
      reserveId,
      selectedCategory,
      mainWishNameEnable,
      subWishNameEnable,
      historyLoading,
      mailLoading,
      writingContents,
      mailTemplateClass,
      mailSubject,
      mailBody,
      mailHistoryData,
      mailHistoryId,
      isCopyMode,

      /* computed */
      mainWishList,
      subWishList,
      selectedMailTemplates,
      mailDialogButtonEnable,

      /* events */
      onPrayDateBlur,
      onWishIdSelectedChange,
      onPostCodeSearchClick,
      // onRemarksCopyClick,
      onRegisterButtonClick,
      onMailSendClick,
      onMailBodyCreateClick,
      onMailDraftSaveClick,
      onMailDialogOpenClick,
      onHistoryMailClick,
      onDownloadReadingSlipClick,
      onCancelButtonClick,
      onCancelRestoreButtonClick,
      onCopyButtonClick,
      onSlipRemarkClear,
      setPersonalDummy,
      setCorpDummy
    }
  }
}
</script>
<style scoped>
.main-content {
  padding-bottom: 60px;
}
.card-header {
  background-color: var(--bs-success-bg-subtle);
  text-align: left;
}

.history-list {
  max-height: 15vh;
  overflow-y: scroll;
}
</style>
