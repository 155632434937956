export default {

  clearInvalidMessage () {
    const elems = document.querySelectorAll('.is-invalid')

    elems.forEach((e) => {
      e.classList.remove('is-invalid')
      const f = document.getElementById(e.getAttribute('aria-describedby'))
      if (f) {
        f.innerText = ''
      }
    })
  },

  setInvalidMessages (list) {
    for (const key in list) {
      const control = document.getElementById(key)
      if (control) {
        control.classList.add('is-invalid')
      } else {
        console.log(`validator.setInvalidMessages() Not Found ${key}`)
      }

      if (control === undefined) {
        console.log(`validator.setInvalidMessages() Not Found invalid-${key}`)
        continue
      }

      const describedby = control.getAttribute('aria-describedby')
      const f = document.getElementById(describedby)
      if (f) {
        f.innerText = list[key]
      } else {
        console.log(`validator.setInvalidMessages() Not Found invalid-${key}`)
      }
    }
  }
}
