<template>
  <div class="continer-fluid">
    <div class="row g-1">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            検索条件
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <span
                    class="input-group-text middle-text">
                    期間
                  </span>
                  <input
                    type="date"
                    class="form-control"
                    id="dateFrom"
                    aria-describedby="date-feedback"
                    v-model="dateFrom">
                  <span
                    class="input-group-text">～</span>
                  <input
                    type="date"
                    class="form-control"
                    id="dateTo"
                    aria-describedby="date-feedback"
                    v-model="dateTo">
                  <div id="date-feedback" class="invalid-feedback"></div>
                </div>
              </div>

              <div class="col-auto">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="onSearchButtonClick">
                  検索
                </button>
              </div>
              <div class="col"></div>
              <div class="col-auto" v-if="isDownloadEnable">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="onDownloadButtonClick">
                  ダウンロード
                </button>
                <a
                  id="download_list_link"
                  href="#"
                  style="display:none;"
                  download="集計表.xlsx">
                  ダウンロード
                </a>
              </div>
              <div class="col-auto" v-else>
                <button
                  type="button"
                  class="btn btn-success"
                  disabled>
                  ダウンロード
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm table-bordered table-hover">
          <thead class="sticky-top">
            <tr>
              <th rowspan="2">日付</th>
              <th colspan="12" class="kitou_odd">祈祷</th>
              <th colspan="6" class="fuda_odd">神札</th>
              <th colspan="5" class="tekka_odd">撤下品</th>
            </tr>
            <tr>
              <th colspan="2" class="kitou_odd">１万</th>
              <th colspan="2">２万</th>
              <th colspan="2" class="kitou_odd">３万</th>
              <th colspan="2">５万</th>
              <th colspan="2" class="kitou_odd">10万</th>
              <th colspan="2">その他</th>
              <th class="fuda_odd">特<br>小</th>
              <th>小</th>
              <th class="fuda_odd">中</th>
              <th>大</th>
              <th class="fuda_odd">特<br>大</th>
              <th>交<br>通</th>
              <th class="tekka_odd">１<br>万</th>
              <th>２<br>万</th>
              <th class="tekka_odd">３<br>万</th>
              <th>５<br>万</th>
              <th class="tekka_odd">10<br>万</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in rows" :key="r.dispPrayDate">

              <td>{{ r.dispPrayDate }}</td>

              <td class="num_cell kitou_odd">
                {{ r.count10000 === 0 ? '' : r.count10000.toLocaleString() }}
              </td>
              <td class="num_cell kitou_odd">
                {{ r.amount10000 === 0 ? '' : r.amount10000.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.count20000 === 0 ? '' : r.count20000.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.amount20000 === 0 ? '' : r.amount20000.toLocaleString() }}
              </td>
              <td class="num_cell kitou_odd">
                {{ r.count30000 === 0 ? '' : r.count30000.toLocaleString() }}
              </td>
              <td class="num_cell kitou_odd">
                {{ r.amount30000 === 0 ? '' : r.amount30000.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.count50000 === 0 ? '' : r.count50000.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.amount50000 === 0 ? '' : r.amount50000.toLocaleString() }}
              </td>
              <td class="num_cell kitou_odd">
                {{ r.count100000 === 0 ? '' : r.count100000.toLocaleString() }}
              </td>
              <td class="num_cell kitou_odd">
                {{ r.amount100000 === 0 ? '' : r.amount100000.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.countOther === 0 ? '' : r.countOther.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.amountOther === 0 ? '' : r.amountOther.toLocaleString() }}
              </td>

              <td class="num_cell fuda_odd">
                {{ r.sizeXs === 0 ? '' : r.sizeXs.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.sizeSm === 0 ? '' : r.sizeSm.toLocaleString() }}
              </td>
              <td class="num_cell fuda_odd">
                {{ r.sizeMd === 0 ? '' : r.sizeMd.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.sizeLg === 0 ? '' : r.sizeLg.toLocaleString() }}
              </td>
              <td class="num_cell fuda_odd">
                {{ r.sizeXl === 0 ? '' : r.sizeXl.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.sizeCar === 0 ? '' : r.sizeCar.toLocaleString() }}
              </td>

              <td class="num_cell tekka_odd">
                {{ r.souvenir01 === 0 ? '' : r.souvenir01.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.souvenir02 === 0 ? '' : r.souvenir02.toLocaleString() }}
              </td>
              <td class="num_cell tekka_odd">
                {{ r.souvenir03 === 0 ? '' : r.souvenir03.toLocaleString() }}
              </td>
              <td class="num_cell">
                {{ r.souvenir04 === 0 ? '' : r.souvenir04.toLocaleString() }}
              </td>
              <td class="num_cell tekka_odd">
                {{ r.souvenir05 === 0 ? '' : r.souvenir05.toLocaleString() }}
              </td>

            </tr>
          </tbody>
          <tfoot class="sticky-bottom" style="background-color: white;">
            <th>合計</th>
            <td class="num_cell kitou_odd">
              {{ rows.reduce((s, e) => s + e.count10000, 0).toLocaleString() }}
            </td>
            <td class="num_cell kitou_odd">
              {{ rows.reduce((s, e) => s + e.amount10000, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.count20000, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.amount20000, 0).toLocaleString() }}
            </td>
            <td class="num_cell kitou_odd">
              {{ rows.reduce((s, e) => s + e.count30000, 0).toLocaleString() }}
            </td>
            <td class="num_cell kitou_odd">
              {{ rows.reduce((s, e) => s + e.amount30000, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.count50000, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.amount50000, 0).toLocaleString() }}
            </td>
            <td class="num_cell kitou_odd">
              {{ rows.reduce((s, e) => s + e.count100000, 0).toLocaleString() }}
            </td>
            <td class="num_cell kitou_odd">
              {{ rows.reduce((s, e) => s + e.amount100000, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.countOther, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.amountOther, 0).toLocaleString() }}
            </td>

            <td class="num_cell fuda_odd">
              {{ rows.reduce((s, e) => s + e.sizeXs, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.sizeSm, 0).toLocaleString() }}
            </td>
            <td class="num_cell fuda_odd">
              {{ rows.reduce((s, e) => s + e.sizeMd, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.sizeLg, 0).toLocaleString() }}
            </td>
            <td class="num_cell fuda_odd">
              {{ rows.reduce((s, e) => s + e.sizeXl, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.sizeCar, 0).toLocaleString() }}
            </td>

            <td class="num_cell tekka_odd">
              {{ rows.reduce((s, e) => s + e.souvenir01, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.souvenir02, 0).toLocaleString() }}
            </td>
            <td class="num_cell tekka_odd">
              {{ rows.reduce((s, e) => s + e.souvenir03, 0).toLocaleString() }}
            </td>
            <td class="num_cell">
              {{ rows.reduce((s, e) => s + e.souvenir04, 0).toLocaleString() }}
            </td>
            <td class="num_cell tekka_odd">
              {{ rows.reduce((s, e) => s + e.souvenir05, 0).toLocaleString() }}
            </td>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import toast from '../lib/toast'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'
// import validator from '../lib/validator'
import excelDownloader from '../lib/excelDownloader'

export default {
  setup () {
    const dateFrom = ref('')
    const dateTo = ref('')
    const rows = ref([])

    const store = useStore()

    onMounted(async () => {
      loadingPanel.loadStart()
      const today = dayjs(new Date())
      dateFrom.value = today.startOf('month').format('YYYY-MM-DD')
      dateTo.value = today.endOf('month').format('YYYY-MM-DD')
      loadingPanel.loadComplete()
    })

    const isDownloadEnable = computed(() => {
      return rows.value.length !== 0
    })

    async function onSearchButtonClick () {
      loadingPanel.loadStart()
      const res = await httpClient.post(
        '/api/v1/Summary/Search',
        {
          dateFrom: dateFrom.value,
          dateTo: dateTo.value
        },
        store.getters.token)

      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      rows.value = res.data.payload
      loadingPanel.loadComplete()
      toast.showSuccessToast({ message: `${rows.value.length}件のデータを取得しました。` })
    }

    async function onDownloadButtonClick () {
      loadingPanel.loadStart()
      const res = await httpClient.requestFileByPost(
        '/api/v1/Report/SummaryList',
        {
          dateFrom: dateFrom.value,
          dateTo: dateTo.value
        },
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      excelDownloader.loadExcelFromBinary(res.data, 'download_list_link')

      toast.showSuccessToast({ message: '出力が完了しました。' })
      loadingPanel.loadComplete()
    }

    return {
      dateFrom,
      dateTo,
      rows,
      isDownloadEnable,
      onSearchButtonClick,
      onDownloadButtonClick
    }
  }
}
</script>
<style scoped>
.num_cell {
  text-align: right;
  padding-right: 5px;
}

.kitou_odd {
  background-color: var(--bs-info-bg-subtle);
}

.fuda_odd {
  background-color: var(--bs-success-bg-subtle);
}

.tekka_odd {
  background-color: var(--bs-warning-bg-subtle);
}
</style>
