const loadExcelFromBinary = (data, elemId) => {
  const bin = atob(data)
  const buf = new ArrayBuffer(bin.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== bin.length; ++i) {
    view[i] = bin.charCodeAt(i) & 0xFF
  }
  const blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  const elem = document.getElementById(elemId)
  elem.href = window.URL.createObjectURL(blob)
  elem.click()
}

export default {
  loadExcelFromBinary
}
