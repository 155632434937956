const loadStart = () => {
  const elem = document.getElementById('loading-panel')
  elem.style.display = null
}

const loadComplete = () => {
  const elem = document.getElementById('loading-panel')
  elem.style.display = 'none'
}

export default {
  loadStart,
  loadComplete
}
