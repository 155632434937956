import ja from './japaneseCharacters'

const getAddressByPostCode = (postcode) => {
  return new Promise((resolve, reject) => {
    fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postcode}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)

        if (data.results === null) {
          reject(new Error(`Not Found: ${postcode}`))
          return
        }

        const res = data.results.map(e => {
          return {
            address1: e.address1,
            address2: e.address2,
            address3: e.address3,
            kana1: ja.toZenKata(e.kana1),
            kana2: ja.toZenKata(e.kana2),
            kana3: ja.toZenKata(e.kana3)
          }
        })
        resolve(res)
      }).catch(e => {
        reject(new Error(e.Error))
      })
  })
}

export default {
  getAddressByPostCode
}
