<template>
  <div class="container-fluid main-content">
    <div class="row g-0">
      <div class="col-9">
        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                申込者情報
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-5">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        申込者名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="applicantName"
                        aria-describedby="applicantName-feedback"
                        v-model="form.reserve.applicantName"
                        readonly>
                      <div id="applicantName-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                  <div class="col-7">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        メール
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="applicantEmailAddress"
                        aria-describedby="applicantEmailAddress-feedback"
                        v-model="form.reserve.applicantEmailAddress"
                        readonly>
                      <div id="applicantEmailAddress-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                  <div class="col-5">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        電話番号
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="applicantTelNo"
                        aria-describedby="applicantTelNo-feedback"
                        v-model="form.reserve.applicantTelNo"
                        readonly>
                      <div id="applicantTelNo-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                祈祷情報
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        祈祷日
                      </span>
                      <input
                        type="date"
                        class="form-control"
                        id="prayDate"
                        aria-describedby="prayDate-feedback"
                        v-model="form.reserve.prayDateInput"
                        @blur="onPrayDateBlur"
                        readonly>
                      <div id="prayDate-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <label
                        class="input-group-text wide-text">
                        祈祷時刻
                      </label>
                      <select
                        class="form-select"
                        id="frameDetailsId"
                        aria-describedby="frameDetailsId-feedback"
                        v-model="form.reserve.frameDetailsId"
                        readonly>
                        <option value="0" selected>未選択</option>
                        <option
                          v-for="f in frameList"
                          :key="f.id"
                          :value="f.id">
                          {{ f.selectItemDisp }}
                        </option>

                      </select>
                      <div id="frameDetailsId-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <label
                        class="input-group-text wide-text">
                        第一願意
                      </label>
                      <select
                        class="form-select"
                        id="mainWishId"
                        aria-describedby="mainWishId-feedback"
                        v-model="form.prayBasic.mainWishId"
                        @change="onWishIdSelectedChange(1)"
                        readonly>
                        <option value="0" selected>未選択</option>
                        <option
                          v-for="w in mainWishList"
                          :key="w.id"
                          :value="w.id">
                          {{ w.wishName }}
                        </option>

                      </select>
                      <div id="mainWishId-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        第一願意名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="mainWishName"
                        aria-describedby="mainWishName-feedback"
                        v-model="form.prayBasic.mainWishName"
                        :disabled="!mainWishNameEnable.value">
                      <div id="mainWishName-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <label
                        class="input-group-text wide-text">
                        第二願意
                      </label>
                      <select
                        class="form-select"
                        id="subWishId"
                        aria-describedby="subWishId-feedback"
                        v-model="form.prayBasic.subWishId"
                        @change="onWishIdSelectedChange(2)"
                        readonly>
                        <option value="0" selected>未選択</option>
                        <option
                          v-for="w in subWishList"
                          :key="w.id"
                          :value="w.id">
                          {{ w.wishName }}
                        </option>

                      </select>
                      <div id="subWishId-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        第二願意名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="subWishName"
                        aria-describedby="subWishName-feedback"
                        v-model="form.prayBasic.subWishName"
                        :disabled="!subWishNameEnable.value"
                        readonly>
                      <div id="subWishName-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>

                <template
                  v-if="selectedCategory === 0">
                  <!-- ************************************** -->
                  <!--              法人                      -->
                  <!-- ************************************** -->
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願対象
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyContent"
                          aria-describedby="safetyContent-feedback"
                          v-model="form.categoryCorporate.safetyContent"
                          readonly>
                        <div id="safetyContent-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願期間
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyPeriod"
                          aria-describedby="safetyPeriod-feedback"
                          v-model="form.categoryCorporate.safetyPeriod"
                          readonly>
                        <div id="safetyPeriod-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <template
                  v-if="selectedCategory === 1">
                  <!-- ************************************** -->
                  <!--             個人・一般                  -->
                  <!-- ************************************** -->
                </template>

                <template
                  v-if="selectedCategory === 2">
                  <!-- ************************************** -->
                  <!--             成就・必勝                  -->
                  <!-- ************************************** -->
                  <div class="row mt-1">
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          第一志望
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="firstAspirations"
                          aria-describedby="firstAspirations-feedback"
                          v-model="form.categoryAccomplishment.firstAspirations"
                          readonly>
                        <div id="firstAspirations-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="firstAspirationsKana"
                          aria-describedby="firstAspirationsKana-feedback"
                          v-model="form.categoryAccomplishment.firstAspirationsKana"
                          readonly>
                        <div id="firstAspirationsKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          日付
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="firstAspirationsDateInput"
                          aria-describedby="firstAspirationsDateInput-feedback"
                          v-model="form.categoryAccomplishment.firstAspirationsDateInput"
                          readonly>
                        <div id="firstAspirationsDateInput-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          第二志望
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="secondAspirations"
                          aria-describedby="secondAspirations-feedback"
                          v-model="form.categoryAccomplishment.secondAspirations"
                          readonly>
                        <div id="secondAspirations-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="secondAspirationsKana"
                          aria-describedby="secondAspirationsKana-feedback"
                          v-model="form.categoryAccomplishment.secondAspirationsKana"
                          readonly>
                        <div id="secondAspirationsKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          日付
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="secondAspirationsDateInput"
                          aria-describedby="secondAspirationsDateInput-feedback"
                          v-model="form.categoryAccomplishment.secondAspirationsDateInput"
                          readonly>
                        <div id="secondAspirationsDateInput-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          第三志望
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="thirdAspirations"
                          aria-describedby="thirdAspirations-feedback"
                          v-model="form.categoryAccomplishment.thirdAspirations"
                          readonly>
                        <div id="thirdAspirations-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="thirdAspirationsKana"
                          aria-describedby="thirdAspirationsKana-feedback"
                          v-model="form.categoryAccomplishment.thirdAspirationsKana"
                          readonly>
                        <div id="thirdAspirationsKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          日付
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="thirdAspirationsDateInput"
                          aria-describedby="thirdAspirationsDateInput-feedback"
                          v-model="form.categoryAccomplishment.thirdAspirationsDateInput"
                          readonly>
                        <div id="thirdAspirationsDateInput-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <template
                  v-if="selectedCategory === 3">
                  <!-- ************************************** -->
                  <!--             初宮・七五三                -->
                  <!-- ************************************** -->
                </template>

                <template
                  v-if="selectedCategory === 4">
                  <!-- ************************************** -->
                  <!--             夫婦祈願                    -->
                  <!-- ************************************** -->
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          出産予定日
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="expectedDateOfBirth"
                          aria-describedby="expectedDateOfBirth-feedback"
                          v-model="form.categoryCouple.expectedDateOfBirth"
                          readonly>
                        <div id="expectedDateOfBirth-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <template
                  v-if="selectedCategory === 5">
                  <!-- ************************************** -->
                  <!--             安全祈願                    -->
                  <!-- ************************************** -->
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願対象
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyContent"
                          aria-describedby="safetyContent-feedback"
                          v-model="form.categorySafety.safetyContent"
                          readonly>
                        <div id="safetyContent-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          安全祈願期間
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="safetyPeriod"
                          aria-describedby="safetyPeriod-feedback"
                          v-model="form.categorySafety.safetyPeriod"
                          readonly>
                        <div id="safetyPeriod-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>

                  </div>
                </template>

                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        墨書内容
                      </span>
                      <span
                        class="input-group-text">
                        <div
                          class="form-check form-switch form-check-inline"
                          v-for="wc in writingContents"
                          :key="wc.id">

                          <input
                            type="checkbox"
                            class="form-check-input"
                            :id="`writingContent_${wc.id}`"
                            :value="wc.id"
                            v-model="form.selectedWritingContents"
                            readonly>
                          <label
                            class="form-check-label"
                            :for="`writingContent_${wc.id}`">
                            {{ wc.title }}
                          </label>

                        </div>

                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        祈祷料
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        id="prayFee"
                        aria-describedby="prayFee-feedback"
                        v-model="form.prayBasic.prayFee"
                        readonly>
                      <div id="prayFee-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        参列人数
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        id="participantsNumber"
                        aria-describedby="participantsNumber-feedback"
                        v-model="form.reserve.participantsNumber"
                        readonly>
                      <div id="participantsNumber-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                祈願者情報
              </div>
              <div
                class="card-body">

                <!-- ************************************** -->
                <!--              法人                      -->
                <!-- ************************************** -->
                <template
                  v-if="selectedCategory === 0">
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          会社・団体名
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="companyName"
                          aria-describedby="companyName-feedback"
                          v-model="form.prayerCorprate.companyName"
                          readonly>
                        <input
                          type="text"
                          class="form-control"
                          id="companyNameKana"
                          aria-describedby="companyNameKana-feedback"
                          v-model="form.prayerCorprate.companyNameKana"
                          readonly>
                        <div id="companyName-feedback" class="invalid-feedback"></div>
                        <div id="companyNameKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          支店・部署名
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="branchAndDepartmentName"
                          aria-describedby="branchAndDepartmentName-feedback"
                          v-model="form.prayerCorprate.branchAndDepartmentName"
                          readonly>
                        <input
                          type="text"
                          class="form-control"
                          id="branchAndDepartmentNameKana"
                          aria-describedby="branchAndDepartmentNameKana-feedback"
                          v-model="form.prayerCorprate.branchAndDepartmentNameKana"
                          readonly>
                        <div id="branchAndDepartmentName-feedback" class="invalid-feedback"></div>
                        <div id="branchAndDepartmentNameKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          代表者役職
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="representativeJobTitle"
                          aria-describedby="representativeJobTitle-feedback"
                          v-model="form.prayerCorprate.representativeJobTitle"
                          readonly>
                        <input
                          type="text"
                          class="form-control"
                          id="representativeJobTitleKana"
                          aria-describedby="representativeJobTitleKana-feedback"
                          v-model="form.prayerCorprate.representativeJobTitleKana"
                          readonly>
                        <div id="representativeJobTitle-feedback" class="invalid-feedback"></div>
                        <div id="representativeJobTitleKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="input-group">
                        <span
                          class="input-group-text wide-text">
                          代表者名
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          id="representativeName"
                          aria-describedby="representativeName-feedback"
                          v-model="form.prayerCorprate.representativeName"
                          readonly>
                        <input
                          type="text"
                          class="form-control"
                          id="representativeNameKana"
                          aria-describedby="representativeNameKana-feedback"
                          v-model="form.prayerCorprate.representativeNameKana"
                          readonly>
                        <div id="representativeName-feedback" class="invalid-feedback"></div>
                        <div id="representativeNameKana-feedback" class="invalid-feedback"></div>
                      </div>
                    </div>
                  </div>
                </template>

                <template
                  v-if="selectedCategory !== 0">
                  <!-- ************************************** -->
                  <!--             法人以外                    -->
                  <!-- ************************************** -->
                  <div class="row g-1">
                    <div
                      class="col"
                      v-for="(p, i) in form.prayerPersonal"
                      :key="p.id">

                      <div class="card">
                        <div class="card-header">
                          <template v-if="p.attributes === 0">
                            御本人
                          </template>
                          <template v-if="p.attributes === 1">
                            夫
                          </template>
                          <template v-if="p.attributes === 2">
                            妻
                          </template>
                          <template v-if="p.attributes === 3">
                            お祝い子 {{ i + 1 }}人目
                          </template>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text small-text">
                                  氏名
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`prayerName_${i}`"
                                  :aria-describedby="`prayerName_${i}-feedback`"
                                  v-model="p.prayerName"
                                  readonly>
                                <div :id="`prayerName_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text small-text">
                                  よみ
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`prayerNameKana_${i}`"
                                  :aria-describedby="`prayerNameKana_${i}-feedback`"
                                  v-model="p.prayerNameKana"
                                  readonly>
                                <div :id="`prayerNameKana_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text small-text">
                                  性別
                                </span>
                                <select
                                  class="form-select"
                                  :id="`gendar_${i}`"
                                  :aria-describedby="`gendar_${i}-feedback`"
                                  v-model="p.gendar"
                                  readonly>
                                  <option value="0">未指定</option>
                                  <option value="1">男性</option>
                                  <option value="2">女性</option>
                                </select>
                                <div :id="`gendar_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text small-text">
                                  誕生日
                                </span>
                                <input
                                  type="date"
                                  class="form-control"
                                  :id="`birthdayInput_${i}`"
                                  :aria-describedby="`birthdayInput_${i}-feedback`"
                                  v-model="p.birthdayInput"
                                  readonly>
                                <div :id="`birthdayInput_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text small-text">
                                  祈祷料
                                </span>
                                <input
                                  type="number"
                                  class="form-control"
                                  :id="`prayFee_${i}`"
                                  :aria-describedby="`prayFee_${i}-feedback`"
                                  v-model="p.prayFee"
                                  readonly>
                                <div :id="`prayFee_${i}-feedback`" class="invalid-feedback"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </template>

                <template
                  v-if="selectedCategory === 3">
                  <!-- ************************************** -->
                  <!--             初宮・七五三                -->
                  <!-- ************************************** -->
                  <div class="row mt-1 mb-1">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-header">
                          ご両親情報
                        </div>
                        <div class="card-body">

                          <div class="row mt-1">
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text wide-text">
                                  父氏名
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="fatherName"
                                  aria-describedby="fatherName-feedback"
                                  v-model="form.categoryChild.fatherName"
                                  readonly>
                                <div id="fatherName-feedback" class="invalid-feedback"></div>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="fatherNameKana"
                                  aria-describedby="fatherNameKana-feedback"
                                  v-model="form.categoryChild.fatherNameKana"
                                  readonly>
                                <div id="fatherNameKana-feedback" class="invalid-feedback"></div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-1">
                            <div class="col-12">
                              <div class="input-group">
                                <span
                                  class="input-group-text wide-text">
                                  母氏名
                                </span>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="motherName"
                                  aria-describedby="motherName-feedback"
                                  v-model="form.categoryChild.motherName"
                                  readonly>
                                <div id="motherName-feedback" class="invalid-feedback"></div>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="motherNameKana"
                                  aria-describedby="motherNameKana-feedback"
                                  v-model="form.categoryChild.motherNameKana"
                                  readonly>
                                <div id="motherNameKana-feedback" class="invalid-feedback"></div>

                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="row mt-1">
                  <div class="col-6">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        郵便番号
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="postCode"
                        aria-describedby="postCode-feedback"
                        v-model="form.prayBasic.postCode"
                        readonly>
                      <div id="postCode-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="col"></div>
                  <div class="col-auto">

                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        住所１
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address1"
                        aria-describedby="address1-feedback"
                        v-model="form.prayBasic.address1"
                        readonly>
                      <div id="address1-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address1Kana"
                        aria-describedby="address1Kana-feedback"
                        v-model="form.prayBasic.address1Kana"
                        readonly>
                      <div id="address1Kana-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        住所２
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address2"
                        aria-describedby="address2-feedback"
                        v-model="form.prayBasic.address2"
                        readonly>
                      <div id="address2-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address2Kana"
                        aria-describedby="address2Kana-feedback"
                        v-model="form.prayBasic.address2Kana"
                        readonly>
                      <div id="address2Kana-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        住所３
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address3"
                        aria-describedby="address3-feedback"
                        v-model="form.prayBasic.address3"
                        readonly>
                      <div id="address3-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="address3Kana"
                        aria-describedby="address3Kana-feedback"
                        v-model="form.prayBasic.address3Kana"
                        readonly>
                      <div id="address3Kana-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        領収書宛名
                      </span>
                      <input
                        type="text"
                        class="form-control"
                        id="receiptAddressee"
                        aria-describedby="receiptAddressee-feedback"
                        v-model="form.prayBasic.receiptAddressee"
                        readonly>
                      <div id="receiptAddressee-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-12">
                    <div class="input-group">
                      <span
                        class="input-group-text wide-text">
                        備考
                      </span>
                      <textarea
                        class="form-control"
                        id="remarks"
                        rows="4"
                        aria-describedby="remarks-feedback"
                        v-model="form.prayBasic.remarks"
                        readonly>
                      </textarea>

                      <textarea
                        class="form-control"
                        id="slipRemarks"
                        rows="4"
                        placeholder="読み札用備考"
                        aria-describedby="slipRemarks-feedback"
                        v-model="form.prayBasic.slipRemarks"
                        readonly>
                      </textarea>
                      <div id="slipRemarks-feedback" class="invalid-feedback"></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                ステータス
              </div>
              <div class="card-body">
                <h2>
                  <span class="badge bg-secondary">履歴</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                最終更新情報
              </div>
              <div class="card-body">
                作成：{{ form.reserve.createdAtDisp }}<br>
                更新：{{ form.reserve.updatedAtDisp }}
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                メモ
              </div>
              <div class="card-body">
                <textarea
                  class="form-control"
                  id="memo"
                  rows="8"
                  v-model="form.reserve.memo"
                  readonly>
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'

export default {
  setup () {
    const store = useStore()
    const route = useRoute()

    const form = reactive({
      reserve: {},
      prayBasic: {},
      prayerCorprate: {},
      prayerPersonal: {},
      categoryAccomplishment: {},
      categoryChild: {},
      categoryCorporate: {},
      categoryCouple: {},
      categoryPersonal: {},
      categorySafety: {},
      selectedWritingContents: []
    })
    const wishes = reactive([])
    const frameList = reactive([])
    const historyList = reactive([])
    const historyLoading = ref(true)

    const mailList = reactive([])
    const mailLoading = ref(true)

    const writingContents = reactive([])

    const reserveId = ref(0)

    const mainWishNameEnable = ref(false)
    const subWishNameEnable = ref(false)

    const selectedCategory = ref(-1)

    const mailTemplateClass = ref('')
    const mailSubject = ref('')
    const mailBody = ref('')
    const mailHistoryId = ref(0)
    const mailHistoryData = reactive({
      isSent: false,
      isDraft: false,
      sentAt: ''
    })

    let beforePrayDate = ''

    const mainWishList = computed(() => {
      return wishes
    })

    const subWishList = computed(() => {
      return wishes.filter((w) => {
        return w.prayCategoryId === selectedCategory.value
      })
    })

    const selectedMailTemplates = computed(() => {
      return mailTemplateClass.value === ''
    })

    const mailDialogButtonEnable = computed(() => {
      return mailSubject.value.trim() === '' || mailBody.value.trim() === ''
    })

    onMounted(initialize)

    async function initialize () {
      loadingPanel.loadStart()
      const { id } = route.params
      reserveId.value = id

      const w = await httpClient.get(
        '/api/v1/Wishes/GetAll',
        store.getters.token)

      wishes.splice(0)
      wishes.push(...w.data.payload)

      console.log('onMounted', id)

      const res = await httpClient.get(
        `/api/v1/Reserve/Histry?id=${reserveId.value}`,
        store.getters.token)

      const p = res.data.payload
      form.reserve = p.reserve
      form.prayBasic = p.prayBasic
      form.prayerCorprate = p.prayerCorprate
      form.prayerPersonal = p.prayerPersonal
      form.categoryAccomplishment = p.categoryAccomplishment
      form.categoryChild = p.categoryChild
      form.categoryCorporate = p.categoryCorporate
      form.categoryCouple = p.categoryCouple
      form.categoryPersonal = p.categoryPersonal
      form.categorySafety = p.categorySafety
      form.selectedWritingContents = p.selectedWritingContents

      if (form.reserve.prayDateInput !== '') {
        loadFrameDetails(form.reserve.prayDateInput)
      }

      const wish = getWishByList(form.prayBasic.mainWishId)
      if (wish) {
        selectedCategory.value = wish.prayCategoryId
        console.log(selectedCategory.value)
        loadWritingContents()
      }

      loadingPanel.loadComplete()
    }

    async function loadFrameDetails (dt) {
      if (beforePrayDate === dt) { return }
      const res = await httpClient.get(
        `/api/v1/Frame/GetByCode?code=${dt}`,
        store.getters.token)

      frameList.splice(0)
      frameList.push(...res.data.payload)
      beforePrayDate = dt
    }

    async function loadWritingContents () {
      const res = await httpClient.get(
        `/api/v1/WritingContent/GetByCode?code=${form.prayBasic.mainWishId}`,
        store.getters.token)

      writingContents.splice(0)
      writingContents.push(...res.data.payload)

      form.selectedWritingContents = form.selectedWritingContents.filter(id => {
        const exists = res.data.payload.find(wc => {
          return id === wc.id
        })
        return (exists !== undefined)
      })
    }

    function getWishByList (wid) {
      const wish = wishes.find((w) => {
        return w.id === wid
      })
      return wish
    }

    return {
      /* variables */
      form,
      wishes,
      frameList,
      historyList,
      mailList,
      reserveId,
      selectedCategory,
      mainWishNameEnable,
      subWishNameEnable,
      historyLoading,
      mailLoading,
      writingContents,
      mailTemplateClass,
      mailSubject,
      mailBody,
      mailHistoryData,
      mailHistoryId,

      /* computed */
      mainWishList,
      subWishList,
      selectedMailTemplates,
      mailDialogButtonEnable
    }
  }
}
</script>
<style scoped>
.main-content {
  padding-bottom: 60px;
}
.card-header {
  background-color: var(--bs-gray-200);
  text-align: left;
}

.history-list {
  max-height: 25vh;
  overflow-y: scroll;
}
</style>
