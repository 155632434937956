import { Buffer } from 'buffer'

export default {
  /**
   *
   * @param {String} url
   * @returns
   */
  async get (url, token) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('accept', 'text/plain')
    headers.set('Content-Type', 'application/json;charset=utf-8')
    console.log(token)
    const res = await fetch(
      url,
      {
        headers
      }
    )

    if (res.status !== 200) {
      return { res, data: null, isValid: false }
    }

    const j = await res.json()

    console.log('get', url, headers, res, j)

    return { res, data: j, isValid: true }
  },
  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */
  async post (url, data, token) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('accept', 'text/plain')
    headers.set('Content-Type', 'application/json;charset=utf-8')

    const res = await fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify({ formData: JSON.stringify(data) }),
        headers: headers
      }
    )

    if (res.status !== 200) {
      return { res, data: null, isValid: false }
    }

    const j = await res.json()

    console.log('post', url, headers, data, res, j)

    return { res, data: j, isValid: true }
  },
  /**
   *
   * @param {*} url
   * @param {*} data
   * @returns
   */
  async put (url, data, token) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('accept', 'text/plain')
    headers.set('Content-Type', 'application/json;charset=utf-8')

    const res = await fetch(
      url,
      {
        method: 'PUT',
        body: JSON.stringify({ formData: JSON.stringify(data) }),
        headers: headers
      }
    )

    if (res.status !== 200) {
      return { res, data: null, isValid: false }
    }

    const j = await res.json()

    console.log('put', url, headers, data, res, j)

    return { res, data: j, isValid: true }
  },
  /**
   *
   * @param {*} url
   * @returns
   */
  async delete (url, token) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('accept', 'text/plain')
    headers.set('Content-Type', 'application/json;charset=utf-8')

    const res = await fetch(
      url,
      {
        method: 'DELETE',
        // body: JSON.stringify(data),
        headers: headers
      }
    )

    if (res.status !== 200) {
      return { res, data: null, isValid: false }
    }

    const j = await res.json()

    console.log('put', url, headers, res, j)

    return { res, data: j, isValid: true }
  },

  /**
   * ファイル(Blob)要求 (HTTP-GET)
   * @param {String} url - リクエスト先URL
   * @returns {Promise<Blob>}
   */
  requestFile (url, token) {
    return new Promise((resolve, reject) => {
      this.httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        console.info('httpClient.requestFile', url, config)
        return config
      }, function (error) {
        return Promise.reject(error)
      })

      this.httpClient.get(url, {
        responseType: 'blob'
      })
        .then((e) => {
          console.log(e)
          if (e.data === undefined) {
            reject(e)
            return
          }
          resolve(e.data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  },

  /**
   * ファイル(Blob)要求 (HTTP-POST)
   * @param {String} url - リクエスト先URL
   * @param {Object} data - POSTデータ
   * @returns {Promise<Blob>}
   */
  async requestFileByPost (url, data, token) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('accept', 'text/plain')
    headers.set('Content-Type', 'application/json;charset=utf-8')

    const res = await fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify({ formData: JSON.stringify(data) }),
        headers: headers
      }
    )

    if (res.status !== 200) {
      return { res, data: null, isValid: false }
    }

    const arrayBuffer = await res.arrayBuffer()
    const buffer = Buffer.from(arrayBuffer)

    return { res, data: buffer, isValid: true }
  },

  async ping (url, token) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)
    headers.set('accept', 'text/plain')
    headers.set('Content-Type', 'application/json;charset=utf-8')

    const res = await fetch(
      url,
      {
        headers
      }
    )

    return res.status <= 399
  }

}
