<script>
export default {

}
</script>

<template>
  <div
    id="loading-panel"
    class="position-absolute top-0 start-0 w-100 h-100"
    style="display:none;"
  >
    <div class="text-center position-absolute top-50 start-50 w-100 translate-middle">
      <div
        class="spinner-border text-light"
        role="status"
        style="width: 8rem; height: 8rem;"
      >
        <span class="sr-only" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  #loading-panel {
    background: rgba(0, 0, 0, .5);
    z-index: 10000;
  }
</style>
