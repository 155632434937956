<template>

  <div class="container mt-5">
    <div class="row">
      <div class="col-1 col-sm-1 col-md-3 col-lg-4"></div>
      <div class="col-10 col-sm-10 col-md-6 col-lg-4">

        <div class="card">
          <div class="card-header">
            ログイン
          </div>
          <div class="card-body" style="text-align: left;">
            <div class="mb-3">
              <label
                for="userId"
                class="form-label">
                ユーザID
              </label>
              <input
                type="text"
                class="form-control"
                v-model="userId"
                id="userId">
            </div>

            <div class="mb-3">
              <label
                for="password"
                class="form-label">
                パスワード
              </label>
              <input
                type="password"
                class="form-control"
                v-model="password"
                id="password">
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <button
                type="button"
                class="btn btn-success"
                @click="onLoginButtonClick">
                ログイン
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'

export default {
  setup () {
    const userId = ref('')
    const password = ref('')
    const router = useRouter()

    const store = useStore()

    onMounted(() => {
      store.dispatch('login', {
        jwtToken: '',
        userInfo: {
          nickName: '',
          apiKey: ''
        }
      })
    })

    async function onLoginButtonClick () {
      loadingPanel.loadStart()

      const res = await httpClient.post(
        '/api/v1/User/TryLogin',
        {
          userId: userId.value,
          password: password.value
        },
        store.getters.token)

      console.log(res)
      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        alert(res.data.summaryMessage)
        return
      }

      store.dispatch('login', {
        jwtToken: res.data.payload.authToken,
        userInfo: {
          nickName: res.data.payload.userInfo.userName,
          apiKey: res.data.payload.authToken
        }
      })

      router.push('/')
    }

    return {
      userId,
      password,
      onLoginButtonClick
    }
  }
}
</script>
