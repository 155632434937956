const D_MUD = 'がぎぐげござじずぜぞだぢづでどばびぶべぼぱぴぷぺぽゔヷヺ'
const S_MUD = 'ｶﾞｷﾞｸﾞｹﾞｺﾞｻﾞｼﾞｽﾞｾﾞｿﾞﾀﾞﾁﾞﾂﾞﾃﾞﾄﾞﾊﾞﾋﾞﾌﾞﾍﾞﾎﾞﾊﾟﾋﾟﾌﾟﾍﾟﾎﾟｳﾞﾜﾞｦﾞ'

const D_KIY = 'あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんぁぃぅぇぉっゃゅょ。、ー「」・'
const S_KIY = 'ｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝｧｨｩｪｫｯｬｭｮ｡､ｰ｢｣･'

const toZenKata = (str) => {
  for (let i = 0, len = D_MUD.length; i < len; i++) {
    str = str.split(S_MUD.slice(i * 2, i * 2 + 2)).join(D_MUD.slice(i, i + 1))
  }
  for (let i = 0, len = D_KIY.length; i < len; i++) {
    str = str.split(S_KIY.slice(i, i + 1)).join(D_KIY.slice(i, i + 1))
  }
  return str
}

export default {
  toZenKata
}
