<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-5">
        <div class="card mt-2">
          <div class="card-header">一覧</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <select
                    class="form-select"
                    v-model="selectedCategory">
                    <option selected value="">カテゴリ選択</option>
                    <option
                      v-for="c in prayCategories"
                      :key="c.id"
                      :value="c.id">
                      {{ c.title }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    class="form-select"
                    v-model="selectedClass">
                    <option selected value="">処理選択</option>
                    <option
                      v-for="c in processClasses"
                      :key="c.key"
                      :value="c.key">
                      {{ c.value }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-success mt-1"
                    @click="onTemplateSelectClick(0)">
                    新規作成
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="card-body">
            <div class="row">
              <div
                v-for="t in filteredTemplates.value"
                :key="t.id"
                class="col-12">
                <div class="card">
                  <div class="row g-0">
                    <div class="col-auto">
                      <button
                        type="button"
                        class="btn btn-outline-primary m-2"
                        @click="onTemplateSelectClick(t.id)">
                        <i class="bi bi-pencil-square"></i>
                    </button>
                    </div>
                    <div class="col" style="padding-left: 2rem;">
                      <h5 class="card-title mt-1">
                        <span class="badge rounded-pill bg-primary pr-1">
                          {{ t.prayCategoryName }}
                        </span>
                        <span class="badge rounded-pill bg-success mr-1">
                          {{ t.processClassificationName }}
                        </span>
                        <span class="badge rounded-pill bg-danger mr-1" v-if="t.isActive">
                          ACTIVE
                        </span>
                      </h5>
                      <h6 class="card-subtitle mb-2 text-body-secondary">
                        件名：{{ t.mailSubject }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-7">
        <div
          v-if="visibleEditPanel"
          class="card mt-2">
          <div class="card-header">編集</div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <span
                    class="input-group-text">
                    使用
                  </span>
                  <span
                    class="input-group-text">
                    <div
                      class="form-check form-switch form-check-inline">

                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="isActive"
                        v-model="editTarget.form.isActive">
                      <label
                        class="form-check-label"
                        for="isActive">
                        送信に使用する
                      </label>

                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">

              <div class="col-6">
                <div class="input-group">
                  <label
                    class="input-group-text">
                    祈祷分類
                  </label>
                  <select
                    class="form-select"
                    id="prayCategoryId"
                    aria-describedby="prayCategoryId-feedback"
                    v-model="editTarget.form.prayCategoryId"
                    @change="onCategoryChanged">
                    <option value="" selected>未選択</option>
                    <option
                      v-for="c in prayCategories"
                      :key="c.id"
                      :value="c.id">
                      {{ c.title }}
                    </option>

                  </select>
                  <div id="prayCategoryId-feedback" class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col"></div>
              <div class="col-auto">
                <h4>
                  <span class="badge bg-success" v-if="visibleStatusPanel">
                    更新
                  </span>
                  <span class="badge bg-primary" v-else>
                    新規
                  </span>
                </h4>
              </div>
            </div>
            <div class="row">

              <div class="col-6">
                <div class="input-group">
                  <label
                    class="input-group-text">
                    処理分類
                  </label>
                  <select
                    class="form-select"
                    id="processClassification"
                    aria-describedby="processClassification-feedback"
                    v-model="editTarget.form.processClassification">
                    <option value="" selected>未選択</option>
                    <option
                      v-for="c in processClasses"
                      :key="c.key"
                      :value="c.key">
                      {{ c.value }}
                    </option>

                  </select>
                  <div id="processClassification-feedback" class="invalid-feedback"></div>
                </div>
              </div>

            </div>
            <div class="row">

              <div class="col-12">
                <div class="input-group">
                  <span
                    class="input-group-text">
                    件名
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="mailSubject"
                    aria-describedby="mailSubject-feedback"
                    v-model="editTarget.form.mailSubject">
                  <div id="mailSubject-feedback" class="invalid-feedback"></div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-9">
                <div class="input-group">
                  <span
                    class="input-group-text">
                    本文
                  </span>
                  <textarea
                    class="form-control"
                    id="mailBody"
                    rows="20"
                    aria-describedby="mailBody-feedback"
                    v-model="editTarget.form.mailBody">
                  </textarea>
                  <div id="mailBody-feedback" class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-3">
                <div class="card">
                  <div class="row">
                    <div
                      v-for="p in placeholderList"
                      :key="p.id"
                      class="col-12 d-grid">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-success"
                        @click="onPlaceholderClick(p.id)">
                        {{ p.placeholderDisplay }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row">
                <div class="col"></div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-danger footer-button"
                    v-if="visibleStatusPanel"
                    @click="onDeleteClick">
                    削除
                  </button>
                </div>
                <div class="col"></div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-primary footer-button"
                    v-if="visibleStatusPanel"
                    @click="onRegisterClick">
                    更新
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary footer-button"
                    v-else
                    @click="onRegisterClick">
                    登録
                  </button>
                </div>
              </div>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import toast from '../lib/toast'
import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'
import validator from '../lib/validator'

export default {
  setup () {
    const store = useStore()

    const prayCategories = ref([])
    const processClasses = ref([])
    const templateList = ref([])
    const placeholderList = ref([])

    const selectedCategory = ref('')
    const selectedClass = ref('')

    const selectedId = ref(-1)
    const editTarget = reactive({
      form: {}
    })

    onMounted(async () => {
      loadingPanel.loadStart()
      const pc = await httpClient.get(
        '/api/v1/Wishes/PrayCategories',
        store.getters.token)
      prayCategories.value = pc.data.payload
      const pc2 = await httpClient.get(
        '/api/v1/MailTemplates/ProcessClassification',
        store.getters.token)
      processClasses.value = pc2.data.payload
      loadMailTemplates()
      loadingPanel.loadComplete()
    })

    const filteredTemplates = computed(() => {
      if (selectedCategory.value === '' && selectedClass.value === '') { return templateList }

      const list = templateList.value.filter((t) => {
        return (selectedCategory.value === '' ||
          (t.prayCategoryId === parseInt(selectedCategory.value, 10))) &&
          (selectedClass.value === '' ||
            (t.processClassification === parseInt(selectedClass.value, 10)))
      })

      return ref(list)
    })

    const visibleEditPanel = computed(() => {
      return selectedId.value !== -1
    })

    const visibleStatusPanel = computed(() => {
      return selectedId.value !== 0
    })

    async function loadMailTemplates () {
      const res = await httpClient.get(
        '/api/v1/MailTemplates/GetAll',
        store.getters.token)
      templateList.value = res.data.payload
    }

    async function onTemplateSelectClick (id) {
      loadingPanel.loadStart()
      const res = await httpClient.get(
        `/api/v1/MailTemplates/Find?id=${id}`,
        store.getters.token)
      selectedId.value = id
      editTarget.form = res.data.payload
      loadingPanel.loadComplete()
      if (editTarget.form.prayCategoryId) {
        setTimeout(loadPlaceholderes, 500)
      }
    }

    async function loadPlaceholderes () {
      const id = editTarget.form.prayCategoryId
      const res = await httpClient.get(
        `/api/v1/MailTemplates/Placeholder?id=${id}`,
        store.getters.token)
      placeholderList.value = res.data.payload
    }

    async function onPlaceholderClick (id) {
      const ph = placeholderList.value.find(p => {
        return p.id === id
      })
      if (!ph) { return }
      const text = `{{${ph.placeholderDisplay}}}`
      const body = document.getElementById('mailBody')
      body.setRangeText(text, body.selectionStart, body.selectionEnd)
      body.focus()
    }

    async function onCategoryChanged () {
      loadPlaceholderes()
    }

    async function onDeleteClick () {
      const r = confirm('このテンプレートを削除します。よろしいですか？')
      if (!r) { return false }

      const res = await httpClient.delete(
        `/api/v1/MailTemplates/Delete?id=${editTarget.form.id}`,
        store.getters.token)

      if (res.data.isValid) {
        toast.showSuccessToast({ message: '削除が完了しました' })
        editTarget.form = {}
        selectedId.value = -1
        loadMailTemplates()
      }
      loadingPanel.loadComplete()
    }

    async function onRegisterClick () {
      const r = confirm('このテンプレートの登録を行います。よろしいですか？')
      if (!r) { return false }

      loadingPanel.loadStart()

      const promise = selectedId.value !== 0
        ? httpClient.put(
          `/api/v1/MailTemplates/Update?id=${selectedId.value}`,
          editTarget.form,
          store.getters.token)
        : httpClient.post(
          '/api/v1/MailTemplates/Create',
          editTarget.form,
          store.getters.token)
      const res = await promise

      if (!res.data.isValid) {
        loadingPanel.loadComplete()
        validator.setInvalidMessages(res.data.invalidList)
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }
      toast.showSuccessToast({ message: '登録が完了しました' })
      editTarget.form = res.data.payload
      selectedId.value = editTarget.form.id
      loadMailTemplates()
      loadingPanel.loadComplete()
    }

    return {
      prayCategories,
      processClasses,
      selectedCategory,
      selectedClass,
      visibleEditPanel,
      selectedId,
      filteredTemplates,
      placeholderList,
      editTarget,
      visibleStatusPanel,
      onTemplateSelectClick,
      onPlaceholderClick,
      onCategoryChanged,
      onDeleteClick,
      onRegisterClick
    }
  }
}
</script>
<style scoped>
.card {
  text-align: left;
}
</style>
