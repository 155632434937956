<template>
  <div class="contaier-fluid main-content">
    <div class="row d-none d-xs-none d-sm-none">
      <div class="col-auto">
        <div class="form-check form-switch m-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="check-autoreload"
            v-model="enableAutoReload"
            @change="onAutoReloadChange">
          <label
            class="form-check-label"
            for="check-autoreload">
            自動更新
          </label>
        </div>
      </div>
      <div class="col-auto" v-if="enableAutoReload">
        <p class="mt-3">最終更新:{{ reloadTimeStamp }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm table-hover d-none d-xs-none d-sm-none d-md-table" v-if="visibleMonth">
          <thead>
            <tr>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onTransMonthClick(beforeYm)">
                  前月
                </button>
              </th>
              <th colspan="6">{{ displayYm }}</th>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onTransMonthClick(afterYm)">
                  翌月
                </button>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>日</th>
              <th>月</th>
              <th>火</th>
              <th>水</th>
              <th>木</th>
              <th>金</th>
              <th>土</th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="w in weekList" :key="w.startDateStr">
              <td>
                <button
                  type="button"
                  class="btn btn-outline-success"
                  @click="onWeekSelectClick(w.startDateStr, w.endDateStr)">
                  週
                </button>
              </td>
              <td v-for="d in w.days" :key="d.prayDate">
                <div class="card d-none d-sm-none d-md-flex">
                  <ul class="list-group list-group-flush">
                    <li :class="`list-group-item bg-status-${d.status}`">
                      <button
                        type="button"
                        class="btn btn-link btn-sm"
                        @click="onDaySelectClick(d.prayDateStr)">
                        {{ d.prayDateDisp }}
                      </button>
                    </li>
                    <li :class="`list-group-item bg-status-${d.status}`" v-if="d.isExists">
                      {{ d.groupCount }} / {{ d.groupLimit }}
                    </li>
                    <li :class="`list-group-item bg-status-${d.status}`" v-if="d.isExists">
                      {{ d.totalPeopleCount }} / {{ d.totalPeopleLimit }}
                    </li>
                  </ul>
                </div>

              </td>
            </tr>

          </tbody>
        </table>
        <table class="table table-sm table-hover d-table d-xs-table d-sm-table d-md-none" v-if="visibleMonth">
          <thead>
            <tr>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onTransMonthClick(beforeYm)">
                  前月
                </button>
              </th>
              <th></th>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onTransMonthClick(afterYm)">
                  翌月
                </button>
              </th>
            </tr>
            <tr>
              <th>日付</th>
              <th>組数</th>
              <th>人数</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="w in weekList" :key="w.startDateStr">
              <tr v-for="d in w.days.filter(dd => dd.isExists)" :key="d.prayDate" :class="`table-${d.status}`">
                <td>
                  <button
                    type="button"
                    class="btn btn-link btn-sm"
                    @click="onDaySelectClick(d.prayDateStr)">
                    {{ d.prayDateFullDisp }}
                  </button>
                </td>
                <td>
                  {{ d.groupCount }} / {{ d.groupLimit }}
                </td>
                <td>
                  {{ d.totalPeopleCount }} / {{ d.totalPeopleLimit }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <table class="table table-sm table-hover" v-if="visibleWeek">
          <thead>
            <tr>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onWeekSelectClick(beforeFromDate, beforeToDate)">
                  前週
                </button>
              </th>
              <th colspan="6">{{ displayFromDate }}～{{ displayToDate }}</th>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onWeekSelectClick(afterFromDate, afterToDate)">
                  翌週
                </button>
              </th>
            </tr>
            <tr v-if="hourList.length !== 0">
              <th></th>

              <th v-for="d in hourList[0].hours" :key="d.id">
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onDaySelectClick(d.prayDateStr)">
                  {{ d.prayDateDisp }}
                </button>
              </th>

            </tr>
          </thead>
          <tbody>

            <tr v-for="h in hourList" :key="h.hour">
              <td>
                {{ h.hourDisp }}
              </td>
              <td
                v-for="d in h.hours"
                :key="d.id">

                <div class="card">
                  <ul class="list-group list-group-flush">
                    <li :class="`list-group-item bg-status-${d.status}`">
                      {{ d.groupCount }} / {{ d.groupLimit }}
                    </li>
                    <li :class="`list-group-item bg-status-${d.status}`">
                      {{ d.totalPeopleCount }} / {{ d.totalPeopleLimit }}
                    </li>
                  </ul>
                </div>

              </td>
            </tr>

          </tbody>
        </table>
        <table class="table table-sm table-hover d-none d-xs-none d-sm-none d-md-table" v-if="visibleDay">
          <thead>
            <tr>
              <th style="width: 100px;">
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onDaySelectClick(beforeDate)">
                  前日
                </button>
              </th>
              <th colspan="2">{{ prayDateDisp }}</th>
              <th style="width: 100px;">
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onDaySelectClick(afterDate)">
                  翌日
                </button>
              </th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="f in frameList" :key="f.id">
              <td :rowspan="f.frameCount" v-if="f.isHourHead">
                {{ f.hourDisp }}
              </td>
              <td
                :class="`bg-status-${f.status}`"
                style="width: 140px;">
                <a
                  class="btn btn-link"
                  target="_blank"
                  :href="`/reserve/edit/0?fid=${f.frameId}&dt=${f.frameDateStr}`">
                  <i class="bi bi-pencil-square me-1"></i>{{ f.frameTimeDisp }}
                </a>
                <br>
                {{ f.groupCount }} / {{ f.groupLimit }}<br>
                {{ f.totalPeopleCount }} / {{ f.totalPeopleLimit }}
              </td>
              <td colspan="2"
                :class="`bg-status-${f.status}`">
                <div class="row">
                  <div
                    class="col"
                    v-for="r in f.reserves"
                    :key="r.reserveId">
                    <div
                      class="card"
                      style="text-align: left;padding-left: 0.75rem;">
                      <div class="row g-0">
                        <div class="col-auto">
                          <a
                            class="btn btn-outline-primary mt-2 ml-2"
                            :href="`/reserve/edit/${r.reserveId}`">
                            <i class="bi bi-pencil-square"></i>
                          </a>
                        </div>
                        <div class="col" style="padding-left: 2rem;">
                          <h5 class="card-title mt-1">
                            {{ r.prayerName }}
                          </h5>
                          <h6 class="card-subtitle mb-2 text-body-secondary">
                            {{ r.mainWishName }} {{ r.subWishName }}
                          </h6>
                        </div>
                        <div class="col-auto" v-if="r.parentReserveId !== 0">
                          <span class="badge text-bg-light">
                            <a
                              :href="`/reserve/edit/${r.parentReserveId}`"
                              target="_blank">
                              追加<br>予約
                            </a>
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
        <table class="table table-sm table-hover d-table d-xs-table d-sm-table d-md-none" v-if="visibleDay">
          <thead>
            <tr>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onDaySelectClick(beforeDate)">
                  前日
                </button>
              </th>
              <th colspan="2">{{ prayDateDisp }}</th>
              <th>
                <button
                  type="button"
                  class="btn btn-link btn-sm"
                  @click="onDaySelectClick(afterDate)">
                  翌日
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="f in frameList" :key="f.id">
              <tr v-if="f.isHourHead">
                <td :rowspan="((f.frameCount * 2) + 1)">
                  {{ f.hourDisp }}
                </td>
              </tr>
              <tr>
                <td colspan="3" :class="`bg-status-${f.status}`">
                  {{ f.frameTimeDisp }}
                </td>
              </tr>
              <tr>
                <td colspan="3" :class="`bg-status-${f.status}`">
                  <div class="row g-0">
                    <div
                      class="col-12 mb-1"
                      v-for="r in f.reserves"
                      :key="r.reserveId">
                      <div
                        class="card"
                        style="text-align: left;padding-left: 0.75rem;">
                        <div class="row g-0">
                          <div class="col-auto">
                            <a
                              class="btn btn-outline-primary mt-2 ml-2"
                              :href="`/reserve/edit/${r.reserveId}`">
                              <i class="bi bi-pencil-square"></i>
                            </a>
                          </div>
                          <div class="col" style="padding-left: 2rem;">
                            <h5 class="card-title mt-1">
                              {{ r.prayerName }}
                            </h5>
                            <h6 class="card-subtitle mb-2 text-body-secondary">
                              {{ r.mainWishName }} {{ r.subWishName }}
                            </h6>
                          </div>
                          <div class="col-auto" v-if="r.parentReserveId !== 0">
                            <span class="badge text-bg-light">
                              <a
                                :href="`/reserve/edit/${r.parentReserveId}`"
                                target="_blank">
                                追加予約
                              </a>
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <nav
      class="navbar fixed-bottom bg-body-tertiary bg-primary"
      data-bs-theme="dark"
      v-if="!visibleMonth">
      <div class="container-fluid">
        <div class="row justify-content-end" style="width: 100%;">
          <div class="col col-lg-2 col-md-3 d-none d-xs-none d-sm-none ">
            <p></p>
          </div>
          <div class="col-auto" v-if="visibleWeek">
            <span style="color:white;">表示期間：{{ displayFromDate }}～{{ displayToDate }}</span>
          </div>
          <div class="col-auto" v-if="visibleWeek">
            <button
                type="button"
                class="btn btn-outline-warning btn-sm"
                @click="onTransMonthClick(currentYmForBack)">
                月カレンダーに戻る
              </button>
          </div>

          <div class="col-auto" v-if="visibleDay">
            <span style="color:white;">表示日：{{ prayDateDisp }}</span>
          </div>
          <div class="col-auto" v-if="visibleDay">
            <button
              type="button"
              class="btn btn-sm btn-outline-success"
              @click="onDayCalendarDownloadClick(currentYm)">
              <i class="bi bi-cloud-arrow-down-fill"></i>
              ダウンロード
            </button>
            <a
              id="download_list_link"
              href="#"
              style="display:none;"
              download="日カレンダー.xlsx">
              ダウンロード
            </a>
          </div>
          <div class="col-auto" v-if="visibleDay">
            <button
              type="button"
              class="btn btn-sm btn-outline-warning"
              @click="onTransMonthClick(currentYm)">
              月カレンダーに戻る
            </button>
          </div>
          <div class="col-auto" v-if="visibleDay">
            <button
              type="button"
              class="btn btn-sm btn-outline-warning ms-2 d-none d-xs-none d-sm-none "
              @click="onWeekSelectClick(currentWeekFromDate, currentWeekToDate)">
              週カレンダーに戻る
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
// import { useRouter } from 'vue-router'
import toast from '../lib/toast'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

import httpClient from '../lib/httpClient'
import loadingPanel from '../lib/loadingPanel'
import excelDownloader from '../lib/excelDownloader'

dayjs.locale('ja')

export default {
  setup () {
    const store = useStore()

    /* Month */
    const displayYm = ref('')
    const beforeYm = ref('')
    const afterYm = ref('')
    const weekList = ref([])

    /* Week */
    const displayFromDate = ref('')
    const displayToDate = ref('')
    const beforeFromDate = ref('')
    const beforeToDate = ref('')
    const afterFromDate = ref('')
    const afterToDate = ref('')
    const currentYmForBack = ref('')
    const hourList = ref([])

    /* Day */
    const prayDateDisp = ref('')
    const currentYm = ref('')
    const beforeDate = ref('')
    const afterDate = ref('')
    const currentWeekFromDate = ref('')
    const currentWeekToDate = ref('')
    const frameList = ref([])

    const visibleMonth = ref(true)
    const visibleWeek = ref(true)
    const visibleDay = ref(true)

    const enableAutoReload = ref(false)
    let intervalId = null

    const reloadYm = ref('')
    const reloadFromDt = ref('')
    const reloadToDt = ref('')
    const reloadDate = ref('')
    const reloadTimeStamp = ref('')

    onMounted(async () => {
      loadingPanel.loadStart()
      const ym = dayjs(new Date()).format('YYYYMM')
      loadMonthCalendar(ym)
      loadingPanel.loadComplete()
    })

    async function loadMonthCalendar (ym) {
      const res = await httpClient.get(
        `/api/v1/Calendar/Month?ym=${ym}`,
        store.getters.token)

      displayYm.value = res.data.payload.yearMonthDisp
      weekList.value = res.data.payload.weekList
      beforeYm.value = res.data.payload.beforeYm
      afterYm.value = res.data.payload.afterYm

      reloadYm.value = ym

      visibleMonth.value = true
      visibleWeek.value = false
      visibleDay.value = false
    }

    async function onTransMonthClick (ym) {
      loadingPanel.loadStart()
      displayFromDate.value = ''
      displayToDate.value = ''
      beforeFromDate.value = ''
      beforeToDate.value = ''
      afterFromDate.value = ''
      afterToDate.value = ''
      hourList.value = []
      currentYmForBack.value = ''

      prayDateDisp.value = ''
      currentYm.value = ''
      beforeDate.value = ''
      afterDate.value = ''
      currentWeekFromDate.value = ''
      currentWeekToDate.value = ''
      frameList.value = []

      loadMonthCalendar(ym)
      loadingPanel.loadComplete()
    }

    async function onWeekSelectClick (f, t) {
      loadingPanel.loadStart()
      prayDateDisp.value = ''
      currentYm.value = ''
      beforeDate.value = ''
      afterDate.value = ''
      currentWeekFromDate.value = ''
      currentWeekToDate.value = ''
      frameList.value = []

      loadWeekCalendar(f, t)
      loadingPanel.loadComplete()
    }

    async function loadWeekCalendar (f, t) {
      const res = await httpClient.get(
        `/api/v1/Calendar/Week?f=${f}&t=${t}`,
        store.getters.token)

      displayFromDate.value = res.data.payload.fromDateDisp
      displayToDate.value = res.data.payload.toDateDisp
      beforeFromDate.value = res.data.payload.beforeFromDateStr
      beforeToDate.value = res.data.payload.beforeToDateStr
      afterFromDate.value = res.data.payload.afterFromDateStr
      afterToDate.value = res.data.payload.afterToDateStr
      hourList.value = res.data.payload.hours
      currentYmForBack.value = res.data.payload.currentYm

      reloadFromDt.value = f
      reloadToDt.value = t

      visibleMonth.value = false
      visibleWeek.value = true
      visibleDay.value = false
    }

    async function onDaySelectClick (d) {
      loadingPanel.loadStart()
      loadDayCalendar(d)
      loadingPanel.loadComplete()
    }

    async function loadDayCalendar (dt) {
      const res = await httpClient.get(
        `/api/v1/Calendar/Day?dt=${dt}`,
        store.getters.token)

      prayDateDisp.value = res.data.payload.prayDateDisp
      currentYm.value = res.data.payload.currentYm
      beforeDate.value = res.data.payload.beforeDateStr
      afterDate.value = res.data.payload.afterDateStr
      currentWeekFromDate.value = res.data.payload.currentWeekFromDateStr
      currentWeekToDate.value = res.data.payload.currentWeekToDateStr
      frameList.value = res.data.payload.frames

      reloadDate.value = dt

      visibleMonth.value = false
      visibleWeek.value = false
      visibleDay.value = true
    }

    function onAutoReloadChange () {
      if (enableAutoReload.value) {
        intervalId = setInterval(reloadCalendars, 60 * 1000)
      } else {
        clearInterval(intervalId)
        intervalId = null
      }
    }

    async function reloadCalendars () {
      if (visibleMonth.value) {
        await onTransMonthClick(reloadYm.value)
      }

      if (visibleWeek.value) {
        await onWeekSelectClick(reloadFromDt.value, reloadToDt.value)
      }

      if (visibleDay.value) {
        await onDaySelectClick(reloadDate.value)
      }

      reloadTimeStamp.value = dayjs().format('YYYY年MM月DD日 HH時mm分ss秒')
    }

    async function onDayCalendarDownloadClick () {
      const dt = reloadDate.value

      loadingPanel.loadStart()
      const res = await httpClient.requestFileByPost(
        `/api/v1/Report/DayCalendarDownload?f=${dt}&t=${dt}`,
        {},
        store.getters.token)

      if (!res.isValid) {
        loadingPanel.loadComplete()
        toast.showDangerToast({ message: res.data.summaryMessage })
        return
      }

      excelDownloader.loadExcelFromBinary(res.data, 'download_list_link')

      toast.showSuccessToast({ message: '出力が完了しました。' })
      loadingPanel.loadComplete()
    }

    return {
      displayYm,
      beforeYm,
      afterYm,
      weekList,

      displayFromDate,
      displayToDate,
      beforeFromDate,
      beforeToDate,
      afterFromDate,
      afterToDate,
      hourList,
      currentYmForBack,

      prayDateDisp,
      currentYm,
      beforeDate,
      afterDate,
      currentWeekFromDate,
      currentWeekToDate,
      frameList,

      reloadTimeStamp,

      visibleMonth,
      visibleWeek,
      visibleDay,
      enableAutoReload,

      onTransMonthClick,
      onWeekSelectClick,
      onDaySelectClick,
      onAutoReloadChange,
      onDayCalendarDownloadClick
    }
  }
}
</script>
<style scoped>
.bg-status-success {
  background: rgba(73,204,144,.1);
  border-color: #49cc90;
}
.bg-status-warning {
  background: rgba(252,161,48,.1);
  border-color: #fca130;
}
.bg-status-danger {
  background: rgba(249,62,62,.1);
  border-color: #f93e3e;
}

.bg-status-success-m {
  color: rgba(73,204,144,.1);
  border-color: #49cc90;
}
.bg-status-warning-m {
  color: rgba(252,161,48,.1);
  border-color: #fca130;
}
.bg-status-danger-m {
  color: rgba(249,62,62,.1);
  border-color: #f93e3e;
}

.main-content {
  padding-bottom: 40px;
}
</style>
