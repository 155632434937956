<template>
  <div
    class="container-fluid"
    id="app">

    <LoadingPanel />

    <div class="toast-container position-fixed top-0 end-0 p-3">
      <div
        id="toast-success"
        class="toast text-bg-success"
        role="alert"
        aria-live="assertive"
        aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto" id="toast-success-header"></strong>
          <button type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"></button>
        </div>
        <div class="toast-body" id="toast-success-message">
          Hello, world! This is a toast message.
        </div>
      </div>
      <div
        id="toast-danger"
        class="toast text-bg-danger"
        role="alert"
        aria-live="assertive"
        aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto" id="toast-danger-header">
          </strong>
          <button type="button"
            class="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"></button>
        </div>
        <div class="toast-body" id="toast-danger-message">
          Hello, world! This is a toast message.
        </div>
      </div>
    </div>

    <div
      class="navbar navbar-expand-lg bg-body-tertiary fixed-top bg-primary"
      data-bs-theme="dark">

      <div class="container-fluid">
        <div class="row">
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-link d-none d-sm-none d-md-inline-block"
              @click="onToggleSidebar(false)"
              v-if="showSidebar && isLogedIn">
              ＜＜
            </button>

            <button
              type="button"
              class="btn btn-link d-none d-sm-none d-md-inline-block"
              @click="onToggleSidebar(true)"
              v-if="!showSidebar && isLogedIn">
              ＞＞
            </button>

            <a class="navbar-brand" href="#">祈祷予約管理システム</a>
          </div>
        </div>
        <div class="col"></div>
        <div class="col-auto d-flex d-sm-flex d-md-none" v-if="isLogedIn">
          <div class="dropdown dropstart">
            <a
              class="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              menu
            </a>

            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item"
                  href="https://manage.hiejinjanihombashisessha.tokyo/KTS/home/index"
                  target="_blank">
                  <i class="bi bi-door-closed me-2"></i>礼典介助
                </a>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/">
                  <i class="bi bi-house-door me-2"></i>ホーム
                </router-link>
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/reserve">
                  <i class="bi bi-search me-2"></i>予約一覧
                </router-link>
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  to="/calendar">
                  <i class="bi bi-calendar3 me-2"></i>カレンダー
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-auto" v-if="isLogedIn">
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav">
              <li class="nav-item dropdown dropstart">

                <button
                  type="button"
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ userInfo.nickName }}
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="dropdown-item" to="/login">
                      <i class="bi bi-door-closed me-2"></i>ログアウト
                    </router-link>
                  </li>
                  <li><hr class="dropdown-divider"></li>

                </ul>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-0">
      <div
        class="col col-lg-2 col-md-3 sidebar d-none d-sm-none d-md-block"
        v-if="isLogedIn && showSidebar">

        <ul class="list-group">

          <router-link to="/">
            <li class="list-group-item d-flex">
              <i class="bi bi-house-door me-2"></i>ホーム
            </li>
          </router-link>

          <router-link to="/reserve">
            <li class="list-group-item d-flex">
              <i class="bi bi-search me-2"></i>予約一覧
            </li>
          </router-link>

          <router-link to="/reserve/edit/0">
            <li class="list-group-item d-flex">
              <i class="bi bi-database-add me-2"></i>予約新規登録
            </li>
          </router-link>

          <router-link to="/reserve/summary">
            <li class="list-group-item d-flex">
              <i class="bi bi-list-ol me-2"></i>集計表
            </li>
          </router-link>

          <router-link to="/calendar">
            <li class="list-group-item d-flex">
              <i class="bi bi-calendar3 me-2"></i>カレンダー
            </li>
          </router-link>

          <router-link to="/maintenance/mailtemplate">
            <li class="list-group-item d-flex">
              <i class="bi bi-envelope-at me-2"></i>メールテンプレート
            </li>
          </router-link>

          <router-link to="/maintenance/schedule">
            <li class="list-group-item d-flex">
              <i class="bi bi-calendar-check me-2"></i>スケジュール
            </li>
          </router-link>

        </ul>

      </div>

      <div class="col content">
        <router-view />
      </div>

    </div>

  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import LoadingPanel from './components/LoadingPanel.vue'
import { Dropdown } from 'bootstrap'
export default {
  components: {
    LoadingPanel
  },
  setup () {
    const showSidebar = ref(true)

    const store = useStore()

    const userInfo = computed(() => store.getters.userInfo)
    const isLogedIn = computed(() => store.getters.isLogedIn)

    onMounted(() => {
      console.log('App.vue', 'onMounted')
      const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
      const dropdownList = [...dropdownElementList]
      console.log('App.vue', 'onMounted', dropdownList)
      dropdownList.map(dropdownToggleEl => new Dropdown(dropdownToggleEl))
    })

    async function onToggleSidebar (flg) {
      showSidebar.value = flg
    }

    return {
      showSidebar,
      userInfo,
      isLogedIn,
      onToggleSidebar
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-left: 0;
  padding-right: 0;
  padding-top: 27px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.sidebar {
  min-height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
  height: calc(100vh - 55px);
  background-color: #343a40!important;
  z-index: 100;
}

.content {
  min-height: calc(100vh - 55px);
  max-height: calc(100vh - 55px);
  height: calc(100vh - 55px);
  overflow: scroll;
}

html, body {
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 100px;
}

div.card-body {
  padding: 5px;
}

div.card-header {
  padding: 4px 20px;
}

input[type="number"] {
  text-align: right;
}

.navbar.fixed-bottom {
  z-index: auto;
  background-color: #343a40!important;
}

.input-group-text {
  border: 0 solid transparent !important;
  border-radius: 0 !important;
}

.invalid-feedback {
  text-align: center;
}

.input-group-text{
  background-color: transparent !important;
}

.input-group-text.wide-text {
  width: 120px;
  text-align: right;
  justify-content: flex-end;
}

.input-group-text.middle-text {
  width: 90px;
  text-align: right;
  justify-content: flex-end;
}

.input-group-text.small-text {
  width: 50px;
  text-align: right;
  justify-content: flex-end;
}

.footer-button {
  width: 120px;
}
</style>
