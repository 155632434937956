import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

const showSuccessToast = ({ header, message }) => {
  const h = header || 'メッセージ'
  showToast(h, message, 'success')
}

const showDangerToast = ({ header, message }) => {
  const h = header || '注意'
  showToast(h, message, 'danger')
}

const showToast = (header, message, type) => {
  const elem = document.getElementById(`toast-${type}`)
  const hdr = document.getElementById(`toast-${type}-header`)
  const msg = document.getElementById(`toast-${type}-message`)
  hdr.innerText = header
  msg.innerText = message
  const toast = bootstrap.Toast.getOrCreateInstance(elem)
  toast.show()
}

export default {
  showSuccessToast,
  showDangerToast
}
