import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ReserveListView from '../views/ReserveListView.vue'
import ReserveEditView from '../views/ReserveEditView.vue'
import ReserveHistoryView from '../views/ReserveHistoryView.vue'
import CalendarView from '../views/CalendarView.vue'
import MailTemplateMaintaineView from '../views/MailTemplateMaintaineView.vue'
import ScheduleEditView from '../views/ScheduleEditView.vue'
import ReserveSummaryView from '../views/ReserveSummaryView.vue'

import store from '@/store'
import httpClient from '../lib/httpClient'

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: { allowAnonymous: true }
  },
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/reserve',
    name: 'ReserveListView',
    component: ReserveListView
  },
  {
    path: '/reserve/edit/:id',
    name: 'ReserveEditView',
    component: ReserveEditView
  },
  {
    path: '/calendar',
    name: 'CalendarView',
    component: CalendarView
  },
  {
    path: '/reserve/history/:id',
    name: 'ReserveHistoryView',
    component: ReserveHistoryView
  },
  {
    path: '/maintenance/mailtemplate',
    name: 'MailTemplateMaintaineView',
    component: MailTemplateMaintaineView
  },
  {
    path: '/maintenance/schedule',
    name: 'ScheduleEditView',
    component: ScheduleEditView
  },
  {
    path: '/reserve/summary',
    name: 'ReserveSummaryView',
    component: ReserveSummaryView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.allowAnonymous) {
    next()
    return
  }

  const res = await httpClient.ping(
    '/api/v1/User/Ping',
    store.getters.token)

  if (res) {
    next()
  } else {
    next({ name: 'LoginView' })
  }
})

export default router
